<template>
    <div v-if="active" :id="'modal-alert__'+type" class="modal-alert" :class="{hidden: !alertVisible}">
        <div v-if="type === 'warning'" class="modal-alert-icon-box">
            <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.25 15.84a1.3 1.3 0 1 0 0-2.601 1.3 1.3 0 0 0 0 2.601Zm1.32-4.59V5.06h-2.5v6.19h2.5Z" fill="#2B3550"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.25C0 4.59 4.59 0 10.25 0S20.5 4.59 20.5 10.25 15.91 20.5 10.25 20.5 0 15.91 0 10.25ZM10.25 2.5a7.75 7.75 0 1 0 0 15.5 7.75 7.75 0 0 0 0-15.5Z" fill="#2B3550"/>
            </svg>
        </div>
        <div v-else-if="type === 'error'" class="modal-alert-icon-box">
            <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="m13.36 0 .68.3c.95.4 1.83.95 2.6 1.6l.5.43L16.79 6c-.01.15.02.31.1.45.05.12.15.2.27.26l3.16 1.5.09.69a10.85 10.85 0 0 1-.02 3.17l-.1.65-3.14 1.5a.56.56 0 0 0-.26.24c-.08.15-.11.31-.1.47l.37 3.66-.5.42c-.8.66-1.67 1.2-2.61 1.6l-.68.28-2.82-2.21a.49.49 0 0 0-.61 0l-2.79 2.22-.69-.3c-.94-.41-1.81-.95-2.6-1.61l-.5-.43.37-3.65a.82.82 0 0 0-.1-.46.56.56 0 0 0-.26-.25L.2 12.7l-.09-.68a12.5 12.5 0 0 1 0-3.16l.09-.67 3.13-1.5a.75.75 0 0 0 .3-.28c.06-.13.09-.28.07-.42v-.01L3.32 2.3l.5-.43C4.63 1.23 5.5.7 6.44.3L7.11 0l2.8 2.22c.1.07.22.1.34.11.1 0 .21-.04.3-.1m0 0L13.36 0l-2.81 2.22ZM5.95 3.4l.22 2.3c.08.65-.05 1.3-.36 1.89v.01l-.01.02c-.32.56-.8 1.01-1.37 1.3l-1.91.91a10 10 0 0 0 0 1.2l1.9.88c.6.28 1.1.76 1.42 1.35.3.58.43 1.23.36 1.88l-.23 2.31c.26.19.54.36.82.51l1.58-1.25a2.99 2.99 0 0 1 3.73 0l1.61 1.26c.28-.15.56-.32.82-.5l-.23-2.32c-.07-.64.06-1.3.36-1.87.32-.6.81-1.06 1.42-1.35l1.9-.9a8.39 8.39 0 0 0 0-1.17l-1.9-.9c-.6-.3-1.1-.76-1.41-1.35a3.32 3.32 0 0 1-.37-1.88l.23-2.32a7.86 7.86 0 0 0-.82-.51l-1.62 1.27c-.52.41-1.16.64-1.82.65h-.03c-.67 0-1.32-.23-1.85-.64L6.75 2.9c-.28.15-.56.31-.82.5Z" fill="#2B3550"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.54 9.39a1.24 1.24 0 0 0-.51 1.27v.01a1.24 1.24 0 0 0 2.25.46l.01-.02c.14-.2.21-.43.21-.66a1.28 1.28 0 0 0-.77-1.16 1.3 1.3 0 0 0-1.18.1Zm3.21 1.05H14a3.74 3.74 0 1 1-7.21-1.46c.28-.68.76-1.27 1.38-1.68l.02-.01a3.8 3.8 0 0 1 4.72.51c.7.7 1.08 1.65 1.09 2.63l-1.25.01Z" fill="#2B3550"/>
            </svg>
        </div>
        <div v-else class="modal-alert-icon-box">
            <svg width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="m10.488.191 3.771 6.284 6.603 1.321-5.135 5.135 1.313 7.878-6.552-3.931-6.552 3.931 1.313-7.878L.114 7.796l6.603-1.321L10.488.191Zm0 3.549L7.867 8.107l-4.049.81 3.387 3.387-.818 4.905 4.101-2.461 4.1 2.461-.817-4.905 3.387-3.387-4.05-.81-2.62-4.367Z" fill="#2B3550"/>
            </svg>
        </div>
        <div class="modal-alert-intro-box">
            <p class="modal-alert-description"><vue-simple-markdown :source="description"></vue-simple-markdown></p>
        </div>
        <div class="modal-alert-button-box">
            <button v-on:click="alertState()" class="btn-clean btn-dark">Cerrar</button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'alerts',
    props: {
        active: Boolean,
        description: String,
        type: String,
        number: Number,
    },
    data: () => {
        return {
            alertVisible: '',
        }
    },
    mounted(){
        if(sessionStorage.alertVisible){
            this.alertVisible = sessionStorage.alertVisible.value
        }else{
            setTimeout(() => {
                this.alertVisible = true
            }, 1500);
        }

    },
    beforeMount() {
        this.content = this.$store.state.alerts.data
    },

    methods: {
        alertState(){
            this.alertVisible = false
            sessionStorage.alertVisible = this.alertVisible
        }
    }
}
</script>

<style lang="less">
 @import "../../styles/vars.less";
    #modal-alert{
        &__warning{
            background: @white;
            &::before{
                background: @white;
            }
        }
        &__error{
            background: @form-red;
            &::before{
                background: @form-red;
            }
        }
        &__custom{
            background: @white;
            &::before{
                background: @white;
            }
        }
    }
    .modal-alert{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 1170px;
        margin: 0 auto;
        z-index: 3;
        gap: 16px;

        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 35px 65px;

        box-shadow: 0px 0px 4px 2px rgba(100 100 100 / 20%);
        color: @form-blue;

        transform: translate(0, 0);
        transition: transform 1.6s @custom-ease-1, visibility 0s linear 0s;
        visibility: visible;

        &::before{
            content: '';
            position: absolute;
            width: 100vw;
            height: 100%;
            z-index: -1;
        }

        &.hidden{
            transition: transform 0.8s @custom-ease-1, visibility 0s linear .6s;
            visibility: hidden;
            transform: translate(0, -200%)
        }
        &-description{
            font-size: 0.875em;
            line-height: 1.2;
            a{
                text-decoration: underline;
            }
        }
    }
    .modal-alert-button-box{
        display: flex;
        .btn-clean{
            padding: 10px 22px;
            &::after{ content: none;}
        }
        .btn-dark{
            background: @blue-mid;
            color: @grey-mid;
        }
    }
    @media screen and (max-width: @tablet) {
        .modal-alert{
            width: 100%;
            top: 54px;
            padding: 20px 18px;
            text-align: left;
            z-index: 2;

            &:before{
                content: none;
            }
        }
        .modal-alert-button-box{
            .btn-clean{
                font-size: 0.875em;
            }
        }
    }
</style>