export default {
  data: () => {
    return {
      mParticleReady: false,
    }
  },
  methods: {

    mParticleConfigSet(){
      this.initialConfig()
      this.readyCheck()
    },

    initialConfig() {
      window.mParticle = {
        config: {
          isDevelopmentMode: `${process.env.VUE_APP_DEV_MODE}`,
          //logLevel: 'verbose',
/*           identifyRequest: {
            userIdentities: {
              email: '',
            },
          } */
        }
      };
  
      (
        function (t) { window.mParticle = window.mParticle || {}; window.mParticle.EventType = { Unknown: 0, Navigation: 1, Location: 2, Search: 3, Transaction: 4, UserContent: 5, UserPreference: 6, Social: 7, Other: 8 }; window.mParticle.eCommerce = { Cart: {} }; window.mParticle.Identity = {}; window.mParticle.config = window.mParticle.config || {}; window.mParticle.config.rq = []; window.mParticle.config.snippetVersion = 2.2; window.mParticle.ready = function (t) { window.mParticle.config.rq.push(t) }; var e = ["endSession", "logError", "logBaseEvent", "logEvent", "logForm", "logLink", "logPageView", "setSessionAttribute", "setAppName", "setAppVersion", "setOptOut", "setPosition", "startNewSession", "startTrackingLocation", "stopTrackingLocation"]; var o = ["setCurrencyCode", "logCheckout"]; var i = ["identify", "login", "logout", "modify"]; e.forEach(function (t) { window.mParticle[t] = n(t) }); o.forEach(function (t) { window.mParticle.eCommerce[t] = n(t, "eCommerce") }); i.forEach(function (t) { window.mParticle.Identity[t] = n(t, "Identity") }); function n(e, o) { return function () { if (o) { e = o + "." + e } var t = Array.prototype.slice.call(arguments); t.unshift(e); window.mParticle.config.rq.push(t) } } var mp = document.createElement("script"); mp.type = "text/javascript"; mp.async = true; mp.src = ("https:" == document.location.protocol ? "https://jssdkcdns" : "http://jssdkcdn") + ".mparticle.com/js/v2/" + t + "/mparticle.js"; mp.setAttribute('nonce', 'Q29udGVudC1TZWN1cml0eS1Qb2xpY3ktT1U0IyUmCg=='); var c = document.getElementsByTagName("script")[0]; c.parentNode.insertBefore(mp, c) }
      )(`${process.env.VUE_APP_MPARTICLE}`);
    },
  
    readyCheck() {
      window.mParticle.ready(
        function () {
          const mparticleValueCheck = `${process.env.VUE_APP_MPARTICLE.slice(-6)}`
          console.log('mParticle has loaded!', mparticleValueCheck);
        }
      );
    },

  },

}