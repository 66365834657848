<template>
    <div class="modal" :class="{visible, error}">
        {{message}}
    </div>
</template>

<script>
    export default {
        name: 'modal',
        data: () => {
            return {
                visible: false,
                message: "Hello",
                error: false,
            }
        },

        beforeDestroy() {

        },

        methods: {
            showMessage(message, error = false) {
                this.visible = true
                this.error = error
                this.message = message
                setTimeout(() => {
                    this.visible = false
                }, 10000);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/vars.less";

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 40px;
        background-color: @sky-blue;
        color: @blue-mid;
        z-index: 100;
        font-family: @bold;
        font-size: 1.25em;
        line-height: 1.3;
        text-align: center;
        transition: transform .6s @custom-ease-1, visibility 0s linear .6s;
        visibility: hidden;
        transform: translate(0, -100%);
        &.visible {
            transform: translate(0, 0);
            transition: transform .6s @custom-ease-1, visibility 0s linear 0s;
            visibility: visible;
        }
        &.error {
            background-color: @form-red;
            color: @blue-mid;
        }
        &.warning{
            background-color: @sunny-yellow;
            color: @blue-mid;
        }
    }

</style>