<template>
  <div class="snack-card-block" :data-name="category">
      <img v-if="imgUrl" :src="imgUrl" alt="" class="feature-cards-block-background-image snack-card-image">
      <h1 v-if="type === 'highlight'" class="snack-card-title">{{heading}}</h1>
      <h2 v-else class="snack-card-title">{{heading}}</h2>
      <div class="snack-card-category" :class="category">
          <span class="snack-card-category-item">
            <svg class="logo" data-name="lulo_logo_picto" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" x="0" y="0">
            <path class="minimal-picto" d="M8.9115 13.1465H4.67788H0.441406V8.91003V4.67356V0.439941H4.67788H8.9115V4.67356H4.67788V8.91003H8.9115V4.67356H13.148V8.91003V13.1465H8.9115Z" fill="#FCFBF2"/>
            </svg>
          </span>
          <span class="snack-card-category-item">snacks</span>
          <span class="snack-card-category-item">{{category}}</span>
      </div>
      <p class="snack-card-subtitle ">{{subHeading}}</p>
      <template>
          <a class="snack-card-link" :href="`../snacks/`+ slug"></a>
          <router-link :to="`../snacks/`+ slug" class="snack-card-link" :data-name="`../snacks/`+ slug"></router-link>
      </template>
  </div>
</template>

<script>

import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'snack-card',
    mixins: [SectionMixin],
    components:{
    },
    props: {
        type: String,
        slug: String,
        heading: String,
        subHeading: String,
        category: String,
        imgUrl: String,
    },
}
</script>

<style lang="less" scoped>
    @import "../../../styles/vars.less";

    .snack-card-block{
        position: relative;
        display: inline-flex;
        flex-direction: column;
        padding: 12px 12px 76px;
        margin-bottom: 28px;
        background: @grey;
        transition: transform 0.3s @custom-ease-1, box-shadow 0.25s @custom-ease-1;
        
        &.highlight{
            grid-column-start: 1;
            grid-column-end:   3;
            margin-bottom: 6px;

            .snack-card-image{
                max-height: 500px;
            }
        }
        &:hover{
            box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.14);
            transform: matrix(1.01, 0, 0, 1.01, 1, 1);
            z-index: 1;
        }
        &[hidden]{
            display: none;
        }
    }
    .snack-card-image{
        max-height: 220px;
    }
    .snack-card-title{
        margin: 28px 0 18px;
        font-size: 2.063em;
        font-family: @bold;
        color: @blue-dark;
    }
    .snack-card-subtitle{
        font-size: 1.125em;
        line-height: 1.2;
        padding-right: 4%;
    }
    .snack-card-category{
        display: flex;
        margin-bottom: 18px;
        .snack-card-category-item{
            display: inline-block;
            padding: 8px 16px;
            line-height: 1.1;
            font-size: 14px;
            border-radius: 20px;
            color: @white;
            font-family: @bold;
            &:first-of-type{
                padding: 8px 8px;
                display: inline-flex;
                max-width: 30px;
                max-height: 30px;
                align-items: center;
                justify-content: center;
            }
        }
        &.banco{
            .snack-card-category-item{
                background: @tangerine;
            }
        }
        &.plata{
            .snack-card-category-item{
                background: @lavander;
            }
        }
        &.actualidad{
            .snack-card-category-item{
                background: @sky-blue;
            }
        }
    }
    .snack-card-link{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: block;
    }
    @media screen and (max-width: @tablet) {
        .snack-card-block{
            padding: 12px 12px 48px;
            &.highlight{
                grid-column-start: initial;
                grid-column-end:   initial;
            }
        }
        .snack-card-title{
            font-size: 1.6em;
        }
        .snack-card-subtitle{
            font-size: 1em;
        }
    }
</style>