<template>
  <div class="download-app-view">
    <navigation ref="navigation" />
    <modal-cookies ref="modal-cookies" />
    <introduction
      :title="content.heading"
      :description="content.description"
      :cover="getAssetUrl(content.cover.data.attributes.url)"
      :imgUrl="content.cover.data.attributes.ext == '.jpg' || content.cover.data.attributes.ext == '.png' ? getAssetUrl(content.cover.data.attributes.url) : null"
      :videoUrl="content.cover.data.attributes.ext == '.mp4' || content.cover.data.attributes.ext == '.gif' ? getAssetUrl(content.cover.data.attributes.url) : null"
    />
    <footer-section />
    <alerts
      ref="alerts "
      v-for="(item, e) in alerts.popup_alert" :key="e"
      :class="item.type"
      :active="item.active"
      :description="item.description"
      :type="item.alertType"
    />
  </div>
</template>

<script>
// Mixins
import ViewMixin from "@/mixins/viewMixin";

// Components
import Navigation from "@/components/navigation";
import DownloadAppIntroduction from "@/components/download-app/download-app-introduction";
import Footer from "@/components/global/footer";

// Components Global
import ModalCookies from "../components/global/ModalCookies.vue";
import Alerts from "../components/global/alerts.vue";

export default {
  name: "download-app",
  mixins: [ViewMixin],
  data: () => {
    return {
      visible: true,
    };
  },
  components: {
    'navigation': Navigation,
    'modal-cookies': ModalCookies,
    'introduction': DownloadAppIntroduction,
    'alerts': Alerts,
    'footer-section': Footer,
  },
  beforeMount() {
    this.content = this.$store.state.page["download-app"].data.attributes
    this.wording = this.$store.state.wording.data.attributes
    this.alerts = this.$store.state.alerts.data.attributes
  },

  mounted() {
    this.show();
  },

  beforeDestroy() {
    this.$refs.navigation.forcedLight = false;
  },

  methods: {
    show() {
      this.$refs.navigation.hidden = false;
      this.$refs.navigation.forcedLight = true;
    },
  },
};
</script>