<template>
  <div class="snack-cover-block">
      <video v-if="videoUrl" :src="videoUrl" muted autoplay loop playsinline ref="video" class="snack-cover-item"></video>
      <img v-else-if="imgUrl" :src="imgUrl" alt="" class="feature-cards-block-background-image snack-cover-item">
  </div>
</template>

<script>

import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'snack-cover',
    mixins: [SectionMixin],
    components:{
    },
    props: {
      imgUrl: String,
      videoUrl: String,
    },
    mounted() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}
</script>

<style lang="less" scoped>
  @import "../../../styles/vars.less";

  .snack-cover-block{
    position: relative;
    background: @grey;
    display: flex;
    justify-content: center;
  }
  .snack-cover-item{
    max-width: 1056px;
    width: 100%;
  }
</style>