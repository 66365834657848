import SectionMixin from '@/mixins/sectionMixin'
import CreditForm from "@/components/forms/credit-form"


export default {
    name: 'feature-splited-form-block',
    mixins: [SectionMixin],
    components: {
        "credit-form": CreditForm,
    },
    props: {
        number: String,
        imgUrl: String,
        description: String,
        title: String,
        disclaimer: String,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
            this.$refs["form"].visible = true
        },
    }
}