<template>
  <div v-if="wording.cookies" class="modal-cookies" :class="{hidden: !modalVisible}">
      <div class="modal-cookies-intro-box">
        <strong class="modal-cookies-title">{{wording.cookies.title}}</strong>
        <p class="modal-cookies-description">{{wording.cookies.description}}</p>
      </div>
      <div class="modal-cookies-button-box">
          <button v-on:click="modalStorage('true')" class="btn-clean">{{wording.cookies.accept}}</button>
          <a v-on:click="modalStorage('more_information')" :href="wording.cookies.link_more_info"  target="_blank" class="cta btn-clean btn-dark">{{wording.cookies.more_info}}</a>
      </div>
  </div>
</template>

<script>

// External
import mParticleConfig from '@/js/tagging/mpartic.js' 

export default {
    name: 'modal-cookies',
    data: () => {
        return {
            modalVisible: '',
        }
    },
    mounted(){
        
        if(localStorage.modalVisible){
            this.modalVisible = localStorage.modalVisible.value
        }else{
            setTimeout(() => {
                this.modalVisible = true
            }, 10000);
        }
    },
    beforeMount() {
        this.wording = this.$store.state.wording.data.attributes
    },

    methods: {
        modalStorage(eventName){
            this.modalVisible = false
            localStorage.modalVisible = this.modalVisible
            if(mParticleConfig.mParticleReady){
                if(eventName === 'true'){
                    mParticle.logEvent( 'web_cookies', mParticle.EventType.UserPreference, {'action_button':eventName});
                }else{
                    mParticle.logEvent( 'web_cookies', mParticle.EventType.Navigation, {'action_button':eventName});
                }
            }
        },
    }
}
</script>

<style lang="less" scoped>
 @import "../../styles/vars.less";

    .modal-cookies{
        position: fixed;
        bottom: 4%;
        left: 0;
        right:0;
        margin: auto;
        width: 100%;
        max-width: 950px;
        z-index: 2;

        display: grid;
        grid-auto-flow: column;
        gap: 34px;
        padding: 50px 65px;

        background: #FFE7DD;
        border-radius: 5px;
        color: @form-blue;

        transform: translate(0, 0);
        transition: transform 1.6s @custom-ease-1, visibility 0s linear 0s;
        visibility: visible;

        &.hidden{
            transition: transform 0.8s @custom-ease-1, visibility 0s linear .6s;
            visibility: hidden;
            transform: translate(0, 150%)
        }
        
        &-title{
            display: block;
            font-size: 20px;
            font-family: @bold;
            margin-bottom: 25px;
        }
        &-description{
            font-size: 14px;
            line-height: 1.2;
        }
    }
    .modal-cookies-button-box{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 12px;
        justify-self: flex-end;
    }
    .btn-clean{
        padding: 14px 30px;
        &::after{ content: none;}
    }
    .btn-dark{
        background: @blue-mid;
        color: @grey-mid;
    }
    @media screen and (max-width: @tablet) {
        .modal-cookies{
            grid-auto-flow: row;
            width: 94%;
            bottom: 2%;
            padding: 22px;
            text-align: center;
            gap: 20px;
        }
        .modal-cookies-button-box{
            flex-direction: row;
            gap: 12px;
            justify-self: initial;
        }
        .btn-clean{
            padding: 14px;
            font-size: 14px;
        }
    }
</style>