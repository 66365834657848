import SectionMixin from '@/mixins/sectionMixin'
import FeatureTreeSalmon from '@/assets/img/featureTreeSalmon.png';
import FeatureTreeGreen from '@/assets/img/featureTreeGreen.png';
import FeatureTreeBlue from '@/assets/img/featureTreeBlue.png';

export default {
    name: 'feature-impact-block',
    mixins: [SectionMixin],
    props: {
        title: String,
        imgUrl: String,
        description: String,
        link: String,
        link_text: String,
        cards: {},
        number_list_one: String,
        number_list_two: String,
        image_list_one: String,
        image_list_two: String,
        text_list_one: String,
        text_list_two: String,
        tree_type: String,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
            FeatureTreeSalmon: FeatureTreeSalmon,
            FeatureTreeGreen: FeatureTreeGreen,
            FeatureTreeBlue: FeatureTreeBlue
        }
    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}