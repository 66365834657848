class FBO {
  constructor (scene, pixelRatio = 1, format = 'rgb', size = null, depth = false, filter = 'linear') {
    this.scene = scene
    this.gl = scene.gl
    this.pixelRatio = pixelRatio
    this.format = format
    this.size = size
    this.depth = depth
    this.filter = filter
    this.depthTexture = null
    this.previousFrameBuffer = null
    this.preventResize = false

    if (size === null) {
      this.width = this.scene.width * this.pixelRatio
      this.heigth = this.scene.height * this.pixelRatio
    } else {
      this.width = size
      this.heigth = size
    }

    this.createTexture()
    if (depth) {
      this.createDepthTexture()
    }
    this.createFB()

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null)

    window.addEventListener('resize', this.onResize.bind(this))
    this.scene.passes.push(this)
  }

  createTexture () {
    this.targetTexture = this.gl.createTexture()

    let gl = this.gl

    gl.bindTexture(gl.TEXTURE_2D, this.targetTexture)

    let level = 0
    let internalFormat = gl.RGBA
    let border = 0
    let format = gl.RGBA
    let type = gl.UNSIGNED_BYTE
    if (this.format === 'float') {
      type = gl.FLOAT
    }
    let data = null

    gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, this.width, this.heigth, border, format, type, data)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
    if (this.filter == 'nearest') {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
    }
  }

  createDepthTexture() {
    let gl = this.scene.gl

    this.depthTexture = gl.createTexture()
    gl.bindTexture(gl.TEXTURE_2D, this.depthTexture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.DEPTH_COMPONENT, this.width, this.heigth, 0, gl.DEPTH_COMPONENT, gl.UNSIGNED_INT, null)

  }

  onResize () {
    if (this.preventResize) { return }
    let gl = this.gl
    if (this.size === null) {
      this.width = this.scene.width * this.pixelRatio
      this.heigth = this.scene.height * this.pixelRatio
    } else {
      this.width = this.size
      this.heigth = this.size
    }
    gl.deleteTexture(this.targetTexture)
    if (this.depth) {
      gl.deleteTexture(this.depthTexture)
    }
    gl.deleteFramebuffer(this.fb)
    this.createTexture()
    if (this.depth) {
      this.createDepthTexture()
    }
    this.createFB()
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null)
  }

  createFB () {
    let gl = this.gl
    this.fb = gl.createFramebuffer()
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.fb)
    
    const level = 0
    const attachmentPoint = gl.COLOR_ATTACHMENT0
    gl.framebufferTexture2D(gl.FRAMEBUFFER, attachmentPoint, gl.TEXTURE_2D, this.targetTexture, level)
    
    // create a depth renderbuffer
    const depthBuffer = gl.createRenderbuffer()
    gl.bindRenderbuffer(gl.RENDERBUFFER, depthBuffer)
    
    // make a depth buffer and the same size as the targetTexture
    gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, this.width, this.heigth)
    gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depthBuffer)
    if (this.depth) {
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.TEXTURE_2D, this.depthTexture, 0)
    }
    
  }

  preRender () {
    let gl = this.gl
    this.previousFrameBuffer = this.scene.activeFrameBuffer
    this.scene.activeFrameBuffer = this.fb
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.scene.activeFrameBuffer)
    gl.viewport(0, 0, this.width, this.heigth)
    gl.clearColor(this.scene.clearColor[0], this.scene.clearColor[1], this.scene.clearColor[2], this.scene.clearColor[3])
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
  }

  postRender () {
    let gl = this.gl
    this.scene.activeFrameBuffer = this.previousFrameBuffer
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.scene.activeFrameBuffer)
  }

  bind () {
    let gl = this.gl
    gl.bindTexture(gl.TEXTURE_2D, this.targetTexture)
  }
  
  bindDepth(){
    let gl = this.gl
    if (this.depth) {
      gl.bindTexture(gl.TEXTURE_2D, this.depthTexture)  
    }
  }

  getTexture () {
    return this.targetTexture
  }
}

export default FBO
