<template>
    <div class="feature-anchor-links-block">
        <div class="feature-anchor-links-content observed" :class="{hidden: !visible}">
            <button v-if="button_text_one" @click="scrollToElement('feature-block-1')" class="cta"><span>{{ button_text_one }}</span></button>
            <button v-if="button_text_two" @click="scrollToElement('feature-block-2')" class="cta"><span>{{ button_text_two }}</span></button>
            <button v-if="button_text_three" @click="scrollToElement('feature-block-3')" class="cta"><span>{{ button_text_three }}</span></button>
            <button v-if="button_text_four" @click="scrollToElement('feature-block-4')" class="cta"><span>{{ button_text_four }}</span></button>
        </div>
    </div>
</template>

<script>

import SectionMixin from '@/mixins/sectionMixin'

export default {
    name:'feature-anchor-links',
    mixins: [SectionMixin],
    props: {
        button_text_one: String,
        button_text_two: String,
        button_text_three: String,
        button_text_four: String,
    },
    data: () => {
        return {
            visible: false,
        }
    },
    mounted() {
        
    },
    methods: {
        show() {
            console.log("show")
            this.visible = true
        },
        scrollToElement(id) {
            const elementId = document.getElementById(id);
            elementId.scrollIntoView({ behavior: 'smooth' });
        }
    }

}
</script>

<style lang="less">
@import "../../../styles/vars.less";

.feature-anchor-links-block{
    padding: 90px 0;
    background-color: @blue-light;
    .feature-anchor-links-content{
        position: relative;
        display: grid;
        gap: 2%;
        grid-auto-flow: column;
        justify-content: space-between;
        z-index: 1;
        max-width: 1440px;
        margin: auto;
        padding: 0 6.95%;
        transition: transform 1s @custom-ease-1, opacity 1s @custom-ease-1;
        &.hidden{
            opacity: 0;
        }
        button{
            height: 72px;
            padding:0 30px;
            font-size: 1.375em;
            span::after,&::after{
                content: none;
            }
        }
    }
    @media screen and (max-width: @tablet){
    padding: 24px 0;
        .feature-anchor-links-content{
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-auto-flow: row;
            row-gap: 16px;
            button{
                height: 40px;
                font-size: 0.75rem;
                padding: 0;
            }
        }
    }
}
</style>