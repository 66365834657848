import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'feature-vertical-block',
    mixins: [SectionMixin],
    props: {
        title: String,
        description: String,
        link_text: String,
        imgUrl: String,
        videoUrl: String,
    },
    data: () => {
        return{
            isIntersecting: false,
            visible: false,
            autoplay: false,
        }
    },

    methods: {
        show() {
            if (this.visible) {
                setTimeout(() => {
                    this.autoplay = true
                }, 500);
                return
            }
            this.visible = true
        },
    }
}