import RAF from '@/js/utils/raf'
import mParticleConfig from '@/js/tagging/mpartic.js'

export default {
    name: 'navigation',
    data: ()=>{
        return {
            light: false,
            forcedLight: false,
            lightMode: false,
            foccused: true,
            transparent: true,
            hidden: true,
            mobile: false,
            minimal: false,
            mobileHidden: false,
        }
    },
    components:{
    },
    created(){
        this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    beforeMount() {
        this.header = this.$store.state.header.data.attributes
    },
    created(){
        this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
        this.mobileHidden = this.mobile
    },
    mounted() {
        RAF.suscribe('navigation', this.update.bind(this), 10)
        
        let linkItems = document.getElementsByClassName('mParticle-header')
        Array.from(linkItems).forEach(element => {
            let itemAttribute = element.getAttribute('data-name')
            element.addEventListener("click", ()=>{
                if(this.mobile && itemAttribute != 'register'){
                    this.toggleMobileMenu()
                }
                if(mParticleConfig.mParticleReady){
                    mParticle.logEvent( 'web_header', mParticle.EventType.Navigation, {'action_header':itemAttribute} );
                }
            })
        });
    },
    beforeDestroy() {
        RAF.unsuscribe('navigation')
    },
    methods:{
        activate(id){
            let links = this.$el.querySelectorAll(".navigation-list-item")
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active")
                if (links[i].dataset.route == id) {
                    setTimeout(() => {
                        links[i].classList.add("active")
                    }, 500)
                }
            }
        },
        toggleMobileMenu(){
            this.mobileHidden = !this.mobileHidden
        },
        getInternalLink(url){
            return !url.includes("http")
        },
        update(){
            let scroll = window.scrollY

            if (scroll > 10) {
                this.foccused = false
                this.transparent = false
                this.lightMode = false
                if (this.mobile) {
                    this.minimal = true
                }
            } else {
                this.foccused = true
                this.transparent = true
                if (this.light && !this.forcedLight) {
                    this.lightMode = true
                }
                if (this.mobile) {
                    this.minimal = false
                }
            }
        }
    }
}