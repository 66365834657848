<template>
  <div class="security-tips-view">
    <navigation ref="navigation" />
    <header-section class="grey" :title="content.title" :subtitle="content.description" />
    <div v-for="(c,i) in content.content" :key="i" class="block">
        <feature-full-image-block 
            v-if="c.__component == 'features.fullimage' && c.image"
            :imgUrl="c.image.data.attributes.formats ? getAssetUrl(c.image.data.attributes.formats.large.url) : null"
            :videoUrl="c.image.data.attributes.mime == 'video/mp4' ? getAssetUrl(c.image.data.attributes.url) : null"
            :title="c.title"
            :text="c.text"
        />
        <feature-cards-block
            v-if="c.__component == 'features.list' && c.media"
            :imgUrl="c.media.data.attributes.ext == '.jpg' || c.media.data.attributes.ext == '.png' ? getAssetUrl(c.media.data.attributes.formats.large.url) : null"
            :videoUrl="c.media.data.attributes.ext == '.mp4' || c.media.data.attributes.ext == '.gif' ? getAssetUrl(c.media.data.attributes.url) : null"
            :title="c.title"
            :text="c.text"
            :cards="c.content"
            :alternate="c.alternate"
        />
        <feature-items-block
            v-if="c.__component == 'features.cards'"
            :title="c.title"
            :subTitle="c.subTitle"
            :items="c.content"
        />
        <feature-splited-image-block
            v-if="c.__component == 'features.bigtitle' && c.image"
            :imgUrl="c.image.data.attributes.ext == '.jpg' || c.image.data.attributes.ext == '.png' ? getAssetUrl(c.image.data.attributes.url) : null"
            :videoUrl="c.image.data.attributes.ext == '.mp4' || c.image.data.attributes.ext == '.gif' ? getAssetUrl(c.image.data.attributes.url) : null"
            :title="c.title"
            :description="c.description"
            :titleBgColor="c.titleBgColor"
        />
        <feature-splited-text-block
            v-if="c.__component == 'features.splited-text' && c.title"
            :title="c.title"
            :subTitle="c.subTitle"
            :description="c.description"
        />
        <feature-shift-media-block
            v-if="c.__component == 'features.shift-media' && c.media"
            :imgUrl="c.media.data.attributes.ext == '.jpg' || c.media.data.attributes.ext == '.png' ? getAssetUrl(c.media.data.attributes.url) : null"
            :videoUrl="c.media.data.attributes.ext == '.mp4' || c.media.data.attributes.ext == '.gif' ? getAssetUrl(c.media.data.attributes.url) : null"
            :title="c.title"
            :description="c.description"
            :titleBgColor="c.titleBgColor"
            :mediaBgColor="c.mediaBgColor"
            :buttonText="c.buttonText"
            :buttonLink="c.buttonLink"
            :shift="c.shift"
        />
        <feature-splited-form-block
            v-if="c.__component == 'features.splited-form' && c.media"
            :imgUrl="c.media.data.attributes.ext == '.jpg' || c.media.data.attributes.ext == '.png' ? getAssetUrl(c.media.data.attributes.url) : null"
            :videoUrl="c.media.data.attributes.ext == '.mp4' || c.media.data.attributes.ext == '.gif' ? getAssetUrl(c.media.data.attributes.url) : null"
            :description="c.description"
        />
    </div>
    <footer-section />
  </div>
</template>

<script>
// Mixins
import ViewMixin from "@/mixins/viewMixin"
// Components
import Navigation from "@/components/navigation"
import Header from "@/components/global/header"
import Footer from "@/components/global/footer"
// Components Feature
import FeatureFullImageBlock from "@/components/feature/feature-full-image-block"
import FeatureCardsBlock from "@/components/feature/feature-cards-block"
import FeatureItemsBlock from "@/components/feature/feature-items-block"
import FeatureSplitedImageBlock from "@/components/feature/feature-splited-image-block"
import FeatureSplitedTextBlock from "@/components/feature/feature-splited-text-block"
import FeatureShiftMediaBlock from "@/components/feature/feature-shift-media-block"
import FeatureSplitedFormBlock from "@/components/feature/feature-splited-form-block"

export default {
  name: "security-tips",
  mixins: [ViewMixin],
  data: () => {
    return {
        content: {
        title: null,
        description: null,
        content: [],
        }
    };
  },
  components: {
    'navigation': Navigation,
    'header-section': Header,
    'footer-section': Footer,
    'feature-full-image-block': FeatureFullImageBlock,
    'feature-cards-block': FeatureCardsBlock,
    'feature-items-block': FeatureItemsBlock,
    'feature-splited-image-block': FeatureSplitedImageBlock,
    'feature-splited-text-block': FeatureSplitedTextBlock,
    'feature-shift-media-block': FeatureShiftMediaBlock,
    'feature-splited-form-block': FeatureSplitedFormBlock,
  },
  beforeMount() {
    this.content = this.$store.state.page["security-tips"].data.attributes
  },
  mounted() {
    this.show()
    this.$refs.navigation.activate("/tips-de-seguridad")
  },
  beforeDestroy() {
    this.$refs.navigation.forcedLight = false
  },

  methods: {
    show() {
      this.$refs.navigation.hidden = false
      this.$refs.navigation.forcedLight = true
    },
  },
};
</script>

<style lang="less" >
@import "../styles/vars.less";
</style>