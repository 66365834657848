<template>
  <div ref="home" class="home-view">
    <navigation ref="navigation" />
    <modal-cookies ref="modal-cookies"/>
    <home-phone v-if="!mobile" ref="phone"/>
    <home-scroll-invitation ref="scroll-invitation" />
    <home-main-banner ref="main-banner"
      :title="content.main_banner.title"
      :subTitle="content.main_banner.subTitle"
      :buttonLink="content.main_banner.buttonLink"
      :buttonWording="content.main_banner.buttonWording"
    />
    <home-introduction ref="introduction"
     :content="content.concept1"
     :mobileVideo="content.concept1.video ? content.concept1.video.data.attributes.url : '/videos/video-placeholder.mp4'"
     />
    <home-cta-block-1 
      ref="cta-block-1" 
      :title="content.concept2.title"
      :description="content.concept2.description"
      :cta-link="header.download_app.internal_link"
      :cta="wording.buttons.download_lulo_bank"
      :cta-link-two="content.concept2.button_url"
      :cta-two="content.concept2.button_wording"
      :cards="content.services"
      :mobileVideo="content.concept2.video ? content.concept2.video.data.attributes.url : '/videos/video-placeholder.mp4'"
    />
    <home-cta-block-5
      ref="cta-block-5" 
      :title="content.bank_loan.title"
      :description="content.bank_loan.description"
      :cta-link=" header.download_app.internal_link"
      :cta="wording.buttons.download_lulo_bank"
      :cta-link-two="content.bank_loan.button_url"
      :cta-two="content.bank_loan.button_wording"
      :cards="content.bank_loan.content"
      :mobileVideo="content.bank_loan.video ? content.bank_loan.video.data.attributes.url : '/videos/video-placeholder.mp4'"
      right="true"
    />
    <home-cta-block-2
      ref="cta-block-2"
      :title="content.bank_card.title"
      :text="content.bank_card.text"
      :cta-link="header.download_app.internal_link"
      :cta="wording.buttons.download_lulo_bank"
      :cta-link-two="content.bank_card.button_url"
      :cta-two="content.bank_card.button_wording"
    />
    <home-impact
      ref="home-impact" 
      :content="content.impact"
      :cta-link=" header.download_app.internal_link"
      :cta="wording.buttons.download_lulo_bank"
      :mobileVideo="content.impact.video ? content.impact.video.data.attributes.url : '/videos/video-placeholder.mp4'"
      right="true"
    />
    <home-impact-cards
      ref="home-impact-cards" 
      :content="content.impact_cards"
    />
    <home-lulo-x
      ref="home-lulo-x"
      :content="content.lulo_x"
    />
<!--     <slider
      ref="slider"
      :title="content.slider.title"
      :items="content.slider.content"
    /> -->
    <footer-section/>
    <alerts ref="alerts " 
      v-for="(item, e) in alerts.popup_alert" :key="e"
      :class="item.type"
      :active="item.active"
      :description="item.description"
      :type="item.alertType"
    />
  </div>
</template>

<script>
// Webgl
import WebglManager from '@/glxp/webglManager'

// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components Home
import Navigation from "@/components/navigation"
import ModalCookies from "@/components/global/ModalCookies"
import HomeScrollInvitation from "@/components/home/home-scroll-invitation"
import HomePhone from "@/components/home/home-phone-2D-container"
import HomeMainBanner from "@/components/home/home-main-banner"
import HomeIntroduction from "@/components/home/home-introduction"
import HomeCtaBlock1 from "@/components/home/home-cta-block-1"
import HomeCtaBlock2 from "@/components/home/home-cta-block-2"
import HomeCtaBlock5 from "@/components/home/home-cta-block-5"
import HomeImpact from "@/components/home/home-impact"
import HomeImpactCards from "@/components/home/home-impact-cards"
import HomeLuloX from "@/components/home/home-lulo-x"
/* import Slider from "@/components/global/slider" */
import Footer from "@/components/global/footer"

// Utils 
import { TweenMax } from "gsap"
import when from "when"

// Components Global
import CtaBlock from "@/components/global/cta-block"
import Alerts from "@/components/global/alerts"

export default {
  name: 'home',
  mixins: [ViewMixin],
  data: ()=>{
    return {
      visible: false,

      ogTitle: String,
      ogDescription: String,
      ogImage: String,
      ogImageAlt: String,
      ogUrl: String,
    }
  },
  components: {
    'navigation': Navigation,
    'modal-cookies': ModalCookies,
    'alerts': Alerts,
    'home-scroll-invitation': HomeScrollInvitation,
    'home-phone': HomePhone,
    'home-main-banner': HomeMainBanner,
    'home-introduction': HomeIntroduction,
    'home-cta-block-1': HomeCtaBlock1,
    'home-cta-block-2': HomeCtaBlock2,
    'home-cta-block-5': HomeCtaBlock5,
    'home-impact': HomeImpact,
    'home-impact-cards': HomeImpactCards,
    'home-lulo-x' : HomeLuloX,
/*     'slider': Slider, */
    'footer-section': Footer,
  },

  metaInfo() {
    return{
    //title: 'My Awesome Webapp',
      meta: [
        { vmid: 'og:title', property: 'og:title', name: 'twitter:title', content: this.ogTitle },
        { vmid: 'og:description', property: 'og:description', name: 'twitter:description', content: this.ogDescription },
        { vmid: 'og:image', property: 'og:image', name: 'twitter:image', itemprop:'image', content: this.ogImage },
        { vmid: 'og:image:secure_url', property: 'og:image:secure_url', name: 'twitter:image', itemprop:'image', content: this.ogImage },
        { vmid: 'og:image:alt',  property: 'og:image:alt', content: this.ogImageAlt },
        { vmid: 'og:url', property: 'og:url', content: this.ogImageUrl },
      ]
    }
  },

  mounted () {

    let promises = []

    this.$nextTick(()=>{
      window.scrollTo(0, 0)
      this.glxp = WebglManager.getScene('main')
      this.glxp.background.reset()
      let defer = when.defer()
      promises.push(defer.promise)
      WebglManager.loadScene('main').then(()=>{
        WebglManager.activate('main')
        defer.resolve()
      })
    })
    
    this.show()
    
    if (this.$refs.phone) {
      this.$refs.phone.show()
      
      let defer = when.defer()
      promises.push(defer.promise)
      this.$refs.phone.$refs.video[0].addEventListener("canplaythrough", ()=>{
        defer.resolve()
      })
    }

    when.all(promises).then(()=>{
      if (this.$router.loadingIterations == 1) {
        this.$refs["scroll-invitation"].show()
      }
    })

  },

  beforeMount() {
    this.header = this.$store.state.header.data.attributes
    this.content = this.$store.state.page.home.data.attributes
    this.wording = this.$store.state.wording.data.attributes
    this.alerts = this.$store.state.alerts.data.attributes
    if(this.content.SEO){
      this.ogTitle = this.content.SEO.meta[0].content
      this.ogDescription = this.content.SEO.meta[1].content
      this.ogImage = this.content.SEO.meta[2].content
      this.ogImageAlt = this.content.SEO.meta[3].content
      this.ogImageUrl = this.content.SEO.meta[4].content
    }
  },

  beforeDestroy () {
    this.visible = false
    WebglManager.desactivate('main')

    this.$refs.navigation.forcedLight = false
  },
  
  methods: {
      show(){
        this.$refs.navigation.forcedLight = true
        if (this.visible == true) {
          return
        }
        if (this.$refs.navigation) {
          this.$refs.navigation.hidden = false          
        }
        if (this.mobile) {
          setTimeout(() => {
            this.$el.style.backgroundColor = "#dfe2e7"
          }, 1000);
        }
      },
      loadData() {
        // Manually trigger show animation on return 
        this.show()
        let promise = new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 500); })
        return promise;
      },
  }
}
</script>
