import SectionMixin from '@/mixins/sectionMixin'


export default {
    name: 'header-section',
    mixins: [SectionMixin],
    props: {
        title: String,
        subtitle: String,
        link_text: String,
        fullScreen: Boolean,
        videoUrl: String,
    },
    data: () => {
        return {
            visible: false,
        }
    },
    mounted(){
        this.convertContentText()
    },
    updated(){
        this.convertContentText()
    },
    methods: {
        convertStrikethroughToEm(text){
            return text.replace(/~~\b/gm,' <em>').replace(/~~\s/gm,'</em> ')
        },
        convertToSpam(text){
            return text.replace(/'\b/gm,'<span>').replace(/'\s/gm,'</span>')
        },
        convertContentText(){
            if(this.$refs["markdown"]){
                this.$refs["markdown"].$el.innerHTML = this.convertStrikethroughToEm(this.convertToSpam(this.$refs["markdown"].$el.innerHTML))
            }
        },

        show() {
            this.visible = true
        },

    }
}