<template>
  <div id="app">
    <modal ref="modal" />
    <div ref="webgl-main-container" class="webgl-main-container"></div>
    <transition-panel ref="transition"/>
    <router-view ref="router-view" class="router-view"/>
  </div>
</template>

<script>
import WebglManager from '@/glxp/webglManager'
import RAF from '@/js/utils/raf'
import mParticleEvent from '@/js/tagging/mparticleEvents.js'
import mParticleConfig from '@/js/tagging/mpartic.js' 

import Transition from "@/components/transition"
import Modal from "@/components/global/Modal"

export default {
  name: 'App',
  components: {
    'modal': Modal,
    'transition-panel': Transition,
  },

  mounted () {
    window.APP = this
    const glxpManager = WebglManager.init(this.$refs['webgl-main-container'])
    RAF.suscribe('webgl-main', glxpManager.render.bind(glxpManager))

    console.log.apply(console, ["\n %c  ◾ Lulo Bank ◾ Making history", "color: #1b2132; background: #e8ff00; padding:4px 6px;"])
    
    this.$nextTick(()=>{
      window.scrollTo(0, 0)
    })
  },

 updated() {
    this.$nextTick(function () {
    // will run only after the entire view has been re-rendered
      if(mParticleConfig.mParticleReady){
        mParticle.logPageView(
        mParticleEvent.pageUrl(),
        {page: window.location.href},
        {"Google.Page": window.location.pathname.toString()} // if you're using Google Analytics to track page views
        );
      }
    })
  },

  methods: {
  },
}
</script>

<style src="./styles/index.less" lang="less"></style>
