import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'cta-block',
    mixins: [SectionMixin],
    props: ['title', 'text', 'cta', 'cta-link', 'right','cta-link-two','cta-two'],
    data: () => {
        return {
            visible: false,
        }
    },
    mounted() {
    },
    beforeDestroy() {

    },
    methods: {
        show(){
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}