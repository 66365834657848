import Unlit from '@/glxp/shaders/unlit.glsl'
import Matcap from '@/glxp/shaders/matcap.glsl'
import Post from '@/glxp/shaders/post.glsl'
import Skin from '@/glxp/shaders/skining/simpleSkin.glsl'
import PointDebug from '@/glxp/shaders/pointDebug.glsl'
import Background from '@/glxp/shaders/background.glsl'
import Screen from '@/glxp/shaders/screen.glsl'

// Commons
import Triplanar from '@/glxp/shaders/common/triplanar.glsl'
import ColorBurn from '@/glxp/shaders/common/colorBurn.glsl'
import Rotation from '@/glxp/shaders/common/rotation.glsl'
import NormalBlending from '@/glxp/shaders/common/normalBlending.glsl'
import ScreenBlending from '@/glxp/shaders/common/screenBlending.glsl'
import Blur from '@/glxp/shaders/common/blur5.glsl'
import Simplex3D from '@/glxp/shaders/common/simplex3d.glsl'

export default {
  'unlit': Unlit,
  'post': Post,
  'skin': Skin,
  'point-debug': PointDebug,
  'background': Background,
  'matcap': Matcap,
  'screen': Screen,

  'commons': {
    'triplanar': Triplanar,
    'colorBurn': ColorBurn,
    'rotation': Rotation,
    'blur': Blur,
    'normalBlending': NormalBlending,
    'screenBlending': ScreenBlending,
    'simplex3d': Simplex3D,
  }
}
