import Header from "@/components/global/header"

import axios from 'axios'
import jsonCities from '@/assets/colombia-cities-dep.json';

import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet';
import puntoredIcon from '@/assets/img/puntoredIconB.png'

export default {
    name: 'correspondent-search',
    components:{
        'header-section': Header,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LIcon
    },
    props:{
        autocomplete_department_label: String,
        autocomplete_city_label: String,
        autocomplete_error: String,
        button_search: String,
        title: String,
        subtitle: String,
        map_title: String
    },

    data: () => ({
        colombiaCities: jsonCities.data,
        selectedDepartment:null,
        selectedCity: null,
        filteredCities: [],
        correspondentArray: null,
        filteredCoordinates: null,

        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        center: [ 4.762572524280281, -73.54222088907227 ],
        zoom: 6,
        minZoom: 6,
        icon: L.icon({
            iconUrl: puntoredIcon,
            iconSize: [32, 32],
            iconAnchor: [16, -6]
          }),
    }),

    created(){

    },

    mounted(){
        this.$el.addEventListener("submit", (evt)=>{
            evt.preventDefault()
            this.onSubmit()
        })
    },

    methods:{

        onSubmit(){
            this.getData()
        },
        
        departmentChange(value){
            if(value){
                this.filterCities();
            }else{
                this.selectedCity = null;
                this.filteredCities = [];
            }
        },

        filterCities() {
            if (this.selectedDepartment) {
                this.filteredCities = this.colombiaCities
                .filter(loc => loc.department === this.selectedDepartment)
                .map(loc => loc.city)
            }
        },

        getItemText(item) {
            return `${item.department}`;
        },

        getData(){
            axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/correspondent-places?filters[department][$containsi]=${this.selectedDepartment}&filters[city][$containsi]=${this.selectedCity}`)
            .then((response) => {
                this.correspondentArray = JSON.parse(JSON.stringify(response.data.data))
                .filter(item => item.attributes.latitude != null && item.attributes.longitude != null)
            })
            .catch(function (error){
                console.log("error",error)
            })
            .then(() =>{
                this.adjustMapView
            })
        },
        centerUpdated(center){
            this.center = center;
        },
        scrollWindow(top){
            window.scrollTo({
                top: top,
                left: 0,
                behavior: "smooth",
              });
        }
    },

    computed: {
        items() {
            if (this.correspondentArray.length > 0) {
                return this.correspondentArray
            }
        },
        adjustMapView() {
            this.filteredCoordinates = this.correspondentArray.map((loc) => [
                loc.attributes.latitude,
                loc.attributes.longitude,
            ]);
            const bounds = L.latLngBounds(this.filteredCoordinates);
            this.$refs.map.mapObject.fitBounds(bounds, {
                padding: [50, 50],
            });
        },
    },

    watch: {

    },
}