import SectionMixin from '@/mixins/sectionMixin'
import Slider from '@/components/global/slider'
import TextWysiwyg from '@/components/global/text-wysiwyg'

export default {
    name: 'feature-items-block',
    mixins: [SectionMixin],
    components: {
        'slider': Slider,
        'text-wysiwyg': TextWysiwyg,
    },
    props: {
        number: String,
        title: String,
        subTitle: String,
        items: Array,
        alternate: Boolean,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
        imageFormat(rawFormat){
            switch (true) {
                case (rawFormat === '.png'):
                    return 1
                case (rawFormat === '.jpg'):
                    return 1
                case (rawFormat === '.jpeg'):
                    return 1
                case (rawFormat === '.svg'):
                    return 1
                case (rawFormat === '.gif'):
                    return 1
                default:
                    break;
            }
        },
        videoFormat(rawFormat){
            switch (true) {
                case (rawFormat === '.mp4'):
                    return 1
                case (rawFormat === '.mpeg'):
                    return 1
                default:
                    break;
            }
        }
    }
}