<template>
      <div>
        <navigation ref="navigation" />
        <div class="box-error">
            <video class="box-error--opacity" muted autoplay loop playsinline src="/videos/error_404.mp4"></video>
            <h1 class="box-error-title box-error--opacity">Error<br><strong>404</strong></h1>
            <p class="box-error-description box-error--opacity">{{content.title}}</p>
            <div v-on:click="returnEvent" class="cta-box box-error--opacity">
                <router-link to="/" class="cta">Devolverme</router-link>
            </div>
        </div>
        <footer-section/>
    </div>
</template>

<script>
// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components
import Navigation from "@/components/navigation"
import Footer from "@/components/global/footer"

import mParticleConfig from '@/js/tagging/mpartic.js'

export default {
    name: 'not-found',
    mixins: [ViewMixin],
    props: ["title"],
    data: ()=>{
        return {
            visible: true
            }
    },
    components: {
        'navigation': Navigation,
        'footer-section': Footer,
    },
    
    beforeMount() {
        this.content = this.$store.state.page["not-found"].data.attributes
        },
    mounted () {
        this.show()
        },
    beforeDestroy () {
        this.$refs.navigation.forcedLight = false
        },
        
    methods: {
        show(){
            this.$refs.navigation.hidden = false
            this.$refs.navigation.forcedLight = true
            },
        returnEvent(){
            if(mParticleConfig.mParticleReady){
                mParticle.logEvent( 'web_error_404_return', mParticle.EventType.Navigation );
            }
        }
    }
}
</script>

<style lang="less" >
@import "../styles/vars.less";

    .box-error{
        position: relative;
        display: block;
        
        padding: 12%;
        line-height: 1.5;
        z-index: 0;

        background: @yellow-error;
        text-align: center;
        overflow: hidden;
        
        &-title{
            padding-top: 2em;

            strong{
                font-size: 4.375rem;
                font-weight: 700;
                font-family: @medium;
            }
        }
        &-description{
            font-size: 1.125em;
            max-width: 555px;
            margin: auto;
            padding-bottom: 24px;
        }
        .cta-box{
            display: inline-block;
        }
        &--opacity{
            opacity: 0;
            animation: opacity_in  1s ease-in forwards;
        }
        &--opacity:nth-child(2){
            animation-delay: 0.5s;
        }
        &--opacity:nth-child(3),
        &--opacity:nth-child(4){
            animation-delay: 2.5s;
        }
        @keyframes opacity_in {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }
    }

</style>