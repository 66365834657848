import MainScene from '@/glxp/scenes/main'

import when from 'when'
import Emitter from 'event-emitter'
import { getGPUTier } from 'detect-gpu';


class Manager {
  constructor() {
    this.scenes = {}
    this.initialized = false
    this.mobile = false

    // this.audio = new AudioManager(this)

    window.GLXP = this
    this._emitter = {}
    Emitter(this._emitter)
    this.on = this._emitter.on.bind(this._emitter)

  }

  init(container) {
    // this.rankGpu()
    // if (this.GPUTier.tier == "GPU_DESKTOP_TIER_3" || this.GPUTier.tier == "GPU_MOBILE_TIER_3") {
    // } else {
    //   this.dpr = Math.min(window.devicePixelRatio, 2)
    // }

    this.dpr = Math.min(window.devicePixelRatio, 2)

    this.width = container.offsetWidth * this.dpr
    this.height = container.offsetHeight * this.dpr
    this.container = container
    this.catchContext()

    this.initialized = true
    this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)

    this.initScenes()

    window.addEventListener('resize', () => {
      this.resize()
    })

    return this
  }

  initScenes() {
    this.addScene('main', MainScene)
  }

  addScene(id, Scene) {
    this.scenes[id] = {
      scene: new Scene(this.container, this),
      active: false,
      callbackCanvas: null
    }
  }

  addStandaloneScene(id, Scene, container){
    this.scenes[id] = {
      scene: new Scene(container, null),
      active: false,
      callbackCanvas: null
    }
  }

  registerCallbackCanvas(id, canvas) {
    this.scenes[id].callbackCanvas = canvas
  }

  getScene(id) {
    return this.scenes[id].scene
  }

  loadScene(id) {
    let defer = when.defer()
    if (this.scenes[id].scene.loaded === false) {
      this.scenes[id].scene.load()
      this.scenes[id].scene.on('loaded', () => {
        this.scenes[id].scene.onResize()
        defer.resolve()
      })
    } else {
      defer.resolve()
    }
    return defer.promise
  }

  activate(id) {
    this.scenes[id].active = true
  }

  resize() {
    this.width = this.container.offsetWidth * this.dpr
    this.height = this.container.offsetHeight * this.dpr
    this.canvas.width = this.width
    this.canvas.height = this.height
    this.canvas.style.maxWidth = this.container.offsetWidth + 'px'
    this.canvas.style.maxHeight = this.container.offsetHeight + 'px'
    this.gl.viewport(0, 0, this.width, this.height)
  }


  desactivate(id) {
    this.scenes[id].active = false
    this.gl.clearColor(0, 0, 0, 0)
    this.gl.clear(this.COLOR_BUFFER_BIT | this.DEPTH_BUFFER_BIT)
    this.gl.colorMask(true, true, true, true)
  }

  rankGpu() {
    this.GPUTier = getGPUTier({
      glContext: this.gl,
      mobileBenchmarkPercentages: [0, 50, 40, 10], // (Default) [TIER_0, TIER_1, TIER_2, TIER_3]
      desktopBenchmarkPercentages: [0, 50, 40, 10], // (Default) [TIER_0, TIER_1, TIER_2, TIER_3]
    });
    console.log(this.GPUTier)
  }

  catchContext() {
    this.canvas = document.createElement('canvas')
    this.canvas.width = this.width
    this.canvas.height = this.height
    this.canvas.style.maxWidth = this.container.offsetWidth + 'px'
    this.canvas.style.maxHeight = this.container.offsetHeight + 'px'
    this.container.appendChild(this.canvas)

    this.gl = this.canvas.getContext('webgl', {
      antialias: false,
      alpha: true
    })
    if (this.gl === undefined) { return }

    // enable extensions

    // VAO Polyfill
    let vaoExt = this.gl.getExtension('OES_vertex_array_object')
    if (vaoExt) {
      this.gl['createVertexArray'] = function () { return vaoExt['createVertexArrayOES']() }
      this.gl['deleteVertexArray'] = function (vao) { vaoExt['deleteVertexArrayOES'](vao) }
      this.gl['bindVertexArray'] = function (vao) { vaoExt['bindVertexArrayOES'](vao) }
      this.gl['isVertexArray'] = function (vao) { return vaoExt['isVertexArrayOES'](vao) }
    }

    this.gl.getExtension('OES_standard_derivatives')
    this.gl.getExtension('EXT_shader_texture_lod')

    this.gl.getExtension('OES_texture_float')
    this.gl.getExtension('OES_texture_float_linear')
    this.gl.getExtension('WEBGL_depth_texture')

  }

  render() {
    for (const scene in this.scenes) {
      if (this.scenes.hasOwnProperty(scene)) {
        const sc = this.scenes[scene]
        if (sc.active === true) {
          sc.scene.render()
        }
      }
    }
  }
}

const out = new Manager()
export default out
