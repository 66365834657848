import { render, staticRenderFns } from "./VtuCredit.vue?vue&type=template&id=31381a56"
import script from "./VtuCredit.vue?vue&type=script&lang=js"
export * from "./VtuCredit.vue?vue&type=script&lang=js"
import style0 from "./VtuCredit.vue?vue&type=style&index=0&id=31381a56&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports