import slugify from "../js/utils/slugify"

export default {
    mounted: function () {
        this.registerIntersectionObject()
    },
    beforeMount() {
        this.wording = this.$store.state.wording
    },
    created(){
        this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    methods: {
        registerIntersectionObject: function () {
            this.$el.$vue = this
        },
        getAssetUrl(path){
            if(path.startsWith('/')){
                return `${process.env.VUE_APP_API_ENDPOINT}${path}`
            }else if(path.startsWith('assets')){
                return `https://${path}`
            }else{
                return `${path}`
            }
        },
        getInternalLink(url){
            return !url.includes("http")
        },
        slugify(s){
            return slugify(s)
        }
    }
}