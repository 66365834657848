import ejs from 'ejs/ejs'
import ShaderManifest from "../shaderManifest"

class Shader {
  constructor(string, passes = 1) {
    this.string = string
    let _passses = {}
    if (passes > 1) {
      this.passes = []
      for (let i = 0; i < (passes - 1); i++) {
        _passses['pass_' + (i + 1)] = true
        this.passes.push(ejs.render(this.string, { frag: false, vert: false, passes: _passses, commons: ShaderManifest.commons }))
        _passses['pass_' + (i + 1)] = false
      }
    }
    this.frag = ejs.render(this.string, { frag: true, vert: false, passes: _passses, commons: ShaderManifest.commons })
    this.vert = ejs.render(this.string, { frag: false, vert: true, passes: _passses, commons: ShaderManifest.commons })
  }
}

export default Shader
