<template>
  <div class="about-view">
    <navigation ref="navigation" />
    <modal-cookies ref="modal-cookies"/>
    <header-section 
        :title="content.heading"
        :subtitle="content.subheading"
    />
    <about-team :content="content" ref="about-team" />
    <!-- <about-team-gallery :content="content.team" /> -->
    <about-philosophy :content="content.philosophy" />
    <footer-section />
  </div>
</template>

<script>
// Webgl
import WebglManager from '@/glxp/webglManager'

// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components About
import Navigation from "@/components/navigation"
import ModalCookies from "@/components/global/ModalCookies"
import Footer from "@/components/global/footer"
import Header from "@/components/global/header"

import AboutTeam from "@/components/about/about-team"
import AboutTeamGallery from "@/components/about/about-team-gallery"
import AboutPhilosophy from "@/components/about/about-philosophy"

export default {
    name: 'about',
    mixins: [ViewMixin],
    data: ()=>{
        return {
            content: {},
            visible: true,
        }
    },
    components: {
        'navigation': Navigation,
        'modal-cookies': ModalCookies,
        'footer-section': Footer,
        'header-section': Header,
        'about-team': AboutTeam,
        'about-team-gallery': AboutTeamGallery,
        'about-philosophy': AboutPhilosophy,
    },

    beforeMount() {
        this.content = this.$store.state.page.about.data.attributes
    },

    mounted () {
        this.$nextTick(()=>{
            this.glxp = WebglManager.getScene('main')
            WebglManager.loadScene('main').then(()=>{
                WebglManager.activate('main')
                this.glxp.skipIntro()
                this.show()
            })
            this.$refs.navigation.activate("/about")
        })
    },

    beforeDestroy () {
        
    },
  
    methods: {
        show(){
            this.$refs.navigation.hidden = false
            setTimeout(() => {
                this.$refs["about-team"].show()
            }, 400)
        }
    }
}
</script>
<style lang="less" scoped>
    @import "../styles/vars.less";

    .about-view {
        @media screen and (max-width: @tablet) {
            background-color: @white-light;
        }
    }
</style>