import CtaBlock from '@/components/global/cta-block';
import { TimelineMax, Power2, Linear, Power1 } from "gsap"
import RAF from '@/js/utils/raf'
import mParticleConfig from '@/js/tagging/mpartic.js'

export default {
    name: 'home-cta-block-2',
    extends: CtaBlock,
    data: ()=>{
        return {
            isIntersecting: false,
            progressTarget: 0,
            progressValue: 0,
            damping : 1
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.updateViewport()
            this.initTimeline()
            window.addEventListener("resize", () => {
                this.updateViewport()
            })
            
            RAF.suscribe('home-card-section-update', this.update.bind(this))

            if (this.mobile) {
                let padding = this.$refs["home-cta-block-2-mobile-block-content container"].offsetHeight + 60
                this.$el.style.marginBottom = padding + "px"
            }

        })
    },

    beforeDestroy(){
        RAF.unsuscribe('home-card-section-update')
    },

    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },

        hide(){
            this.canShowCard = false
        },

        updateViewport() {
            this.viewport = {
                width: window.innerWidth,
                height: window.innerHeight,
            }
        },

        initTimeline() {
            this.tl = new TimelineMax({
                paused: true
            })
            
            // before viewport
            this.tl.fromTo(this.$refs["card-1"], 5, { autoAlpha: 0 }, { autoAlpha: 1 }, "0")
            // this.tl.fromTo(this.$refs["card-1"], 15, { x: this.viewport.width / 3, y: this.viewport.height }, { x: this.viewport.width / 3, y: 0, ease: Linear.easeInOut }, "0")
            
            
            this.tl.fromTo(this.$refs["card-1"], 6, { x: this.viewport.width }, { x: this.viewport.width/3, ease: Power2.easeInOut }, "2")
            this.tl.fromTo(this.$refs["card-1"], 7, { rotation: 90 }, { rotation: 0, ease: Power2.easeInOut }, "2")
    
            if (this.mobile ) {
                this.tl.to(this.$refs["card-2"], 2, { perspective: 800, x: this.viewport.width/6.1, y: this.viewport.height * 1.5, scaleX: 0.6 * 0.85, scaleY: 0.6, rotationX: 45, rotationY: 30, rotationZ: -30, ease: Power2.easeInOut }, "0")
                this.tl.to(this.$refs["card-3"], 2, { perspective: 800, x: this.viewport.width/6.1, y: this.viewport.height * 1.5, scaleX: 0.6 * 0.85, scaleY: 0.6, rotationX: 45, rotationY: 30, rotationZ: -30, ease: Power2.easeInOut }, "0")
                this.tl.to(this.$refs["card-4"], 2, { perspective: 800, x: this.viewport.width/6.1, y: this.viewport.height * 1.5, scaleX: 0.6 * 0.85, scaleY: 0.6, rotationX: 45, rotationY: 30, rotationZ: -30, ease: Power2.easeInOut }, "0")
            } else {
                this.tl.to(this.$refs["card-2"], 2, { perspective: 800, x: this.viewport.width / 4, y: this.viewport.height * 1.5, scaleX: 0.6 * 0.85, scaleY: 0.6, rotationX: 45, rotationY: 30, rotationZ: -30, ease: Power2.easeInOut }, "0")
                this.tl.to(this.$refs["card-3"], 2, { perspective: 800, x: this.viewport.width / 4, y: this.viewport.height * 1.5, scaleX: 0.6 * 0.85, scaleY: 0.6, rotationX: 45, rotationY: 30, rotationZ: -30, ease: Power2.easeInOut }, "0")
                this.tl.to(this.$refs["card-4"], 2, { perspective: 800, x: this.viewport.width / 4, y: this.viewport.height * 1.5, scaleX: 0.6 * 0.85, scaleY: 0.6, rotationX: 45, rotationY: 30, rotationZ: -30, ease: Power2.easeInOut }, "0")
            }
            

            // after viewport            
            this.tl.to(this.$refs["card-2"], 1, { autoAlpha: 1 }, "5")
            this.tl.to(this.$refs["card-3"], 1, { autoAlpha: 1 }, "6")
            this.tl.to(this.$refs["card-4"], 1, { autoAlpha: 1 }, "6")

            this.tl.to(this.$refs["card-1"], 4, { 
                perspective: 800, 
                x: this.mobile ? this.viewport.width/6.1 : this.viewport.width/4,  
                y: -this.viewport.height/5,  
                scaleX: 0.6 * 0.85, 
                scaleY: 0.6, 
                rotationX: 45, 
                rotationY: 30, 
                rotationZ: -30, 
                ease: Power2.easeInOut 
            }, "10")

            this.tl.to(this.$refs["card-1"], 4, { 
                y: -this.viewport.height/4 - 25,  
                ease: Power2.easeInOut,
            }, "12")

            this.tl.to(this.$refs["card-2"], 4, { 
                y: -this.viewport.height/4 + 30,  
                ease: Power2.easeInOut,
            }, "12")
            
            this.tl.to(this.$refs["card-3"], 4, {
                y: -this.viewport.height / 4 + 70,
                ease: Power2.easeInOut,
            }, "12.2")

            this.tl.to(this.$refs["card-4"], 5, {
                y: -this.viewport.height / 4 + 100,
                ease: Power2.easeInOut,
            }, "12.3")

            this.tl.to(this.$refs["card-1"], 6, {
                y: -this.viewport.height - 1300,
                ease: Power2.easeInOut,
            }, "16")

            this.tl.to(this.$refs["card-2"], 6, {
                y: -this.viewport.height - 1200,
                ease: Power2.easeInOut,
            }, "16.1")

            this.tl.to(this.$refs["card-3"], 6, {
                y: -this.viewport.height - 1500,
                ease: Power2.easeInOut,
            }, "16.2")

            this.tl.to(this.$refs["card-4"], 6, {
                y: -this.viewport.height - 2000,
                ease: Power2.easeInOut,
            }, "16.3")

            this.tl.to(this.$refs["card-1"], 3, {
                autoAlpha: 0,
            }, "17.5")

            this.tl.to(this.$refs["card-2"], 3, {
                autoAlpha: 0,
            }, "17.5")

            this.tl.to(this.$refs["card-3"], 3, {
                autoAlpha: 0,
            }, "17.5")

            this.tl.to(this.$refs["card-4"], 3, {
                autoAlpha: 0,
            }, "17.5")

            this.tl.add(()=>{}, "20")

        },
        progress(p) {
 
            if (p.before < 1) {
                this.progressTarget = p.before/2
                this.damping = this.mobile ? 1 : 0.75
            }
            
            if (p.in > 0) {
                this.progressTarget = p.in/2 + .5
                this.damping = this.mobile ? 1 : .5
            }

        },

        update(){

            let tmp = this.progressTarget - this.progressValue
            tmp *= this.damping
            this.progressValue += tmp
            this.tl.progress(this.progressValue)

        },

        mparticleButton(eventName){
            if(mParticleConfig.mParticleReady){
                mParticle.logEvent( 'web_home_'+eventName, mParticle.EventType.Navigation );
            }
        }
    }
}