import Emitter from 'event-emitter'
import Node from './node'

class Scene {
  constructor (container, manager) {
    this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)

    this.dpr          = manager !== null ? manager.dpr : window.devicePixelRatio
    this.width        = container.offsetWidth * this.dpr
    this.height       = container.offsetHeight * this.dpr
    this.container    = container

    this.active       = false
    this.time         = 0
    this.dt           = 0

    this.passes       = []

    if (manager !== null) {
      this.manager    = manager
      this.canvas     = this.manager.canvas
      this.gl         = this.manager.gl
    } else {
      this.catchContext()
    }
    this.inTransition = false
    this.root         = new Node()

    this._emitter = {}
    Emitter(this._emitter)
    this.on = this._emitter.on.bind(this._emitter)

    window.addEventListener('resize', this.onResize.bind(this))
  }

  catchContext () {
    this.canvas           = document.createElement('canvas')
    this.canvas.width     = this.width
    this.canvas.height    = this.height
    this.canvas.style.maxWidth  = this.container.offsetWidth + 'px'
    this.canvas.style.maxHeight = this.container.offsetHeight + 'px'
    this.container.appendChild(this.canvas)

    this.gl = this.canvas.getContext('webgl', {
      antialias: true
    })
    if (this.gl === undefined) { return }

    
    // enable extensions
    // VAO Polyfill
    let vaoExt = this.gl.getExtension('OES_vertex_array_object')
    if (vaoExt) {
      this.gl['createVertexArray'] = function () { return vaoExt['createVertexArrayOES']() }
      this.gl['deleteVertexArray'] = function (vao) { vaoExt['deleteVertexArrayOES'](vao) }
      this.gl['bindVertexArray'] = function (vao) { vaoExt['bindVertexArrayOES'](vao) }
      this.gl['isVertexArray'] = function (vao) { return vaoExt['isVertexArrayOES'](vao) }
    }
    this.gl.getExtension('OES_standard_derivatives')
    this.gl.getExtension('EXT_shader_texture_lod')

    this.gl.getExtension('OES_texture_float')
    this.gl.getExtension('OES_texture_float_linear')
    this.gl.getExtension('OES_texture_half_float')
    this.gl.getExtension('OES_texture_half_float_linear')
    this.gl.getExtension('EXT_color_buffer_half_float')
  }

  resize () {
    for (let i = 0; i < this.passes.length; i++) {
      this.passes[i].onResize()
    }
  }

  onResize () {
    if (this.inTransition && this.mobile) return
    this.width                      = this.container.offsetWidth * this.dpr
    this.height                     = this.container.offsetHeight * this.dpr
    this.canvas.width               = this.width
    this.canvas.height              = this.height
    this.canvas.style.maxWidth      = this.container.offsetWidth + 'px'
    this.canvas.style.maxHeight     = this.container.offsetHeight + 'px'
    this.gl.viewport(0, 0, this.width, this.height)
  }
}

export default Scene
