import SectionMixin from '@/mixins/sectionMixin'
import {TweenMax} from "gsap"

export default {
    name: 'text-wysiwyg',
    props: ["title","description","date","imgUrl","content"],
    mixins: [SectionMixin],
    data: () => {
        return {
            visible: false,
            expanded: false,
            mobile: false,
        }
    },
    mounted() {
       const containSnacks = this.$refs["text-wysiwyg"].classList.contains("snacks")
       const containFeatures = this.$refs["text-wysiwyg"].classList.contains("features")

       this.$refs["markdown"].$el.innerHTML = this.removeBrAfterUl(this.convertBackspacesToBr(this.$refs["markdown"].$el.innerHTML))
       this.$nextTick(()=>{
           if (this.mobile && !containSnacks && !containFeatures) {
               this.computeBounds()
           }
       })
    },

    beforeDestroy() {

    },
    methods: {

        toggleExpand(){
            if (this.mobile) {
                if (this.expanded) {
                    this.expanded = false
                    TweenMax.to(this.$refs["wysiwyg-container"], {
                        height: 0,
                        duration: 1,
                        ease: "Power3.easeInOut",
                    })
                } else {
                    TweenMax.to(this.$refs["wysiwyg-container"], {
                        height: this.heightContainer,
                        duration: 1,
                        ease: "Power3.easeInOut",
                    })
                    this.expanded = true
                }
            }
        },

        computeBounds(){
            this.heightContainer = this.$refs["wysiwyg-container"].offsetHeight
            TweenMax.set(this.$refs["wysiwyg-container"], {
                height: 0
            })
        },

        convertBackspacesToBr(text){
            return text.replace(/\r\n|\n|\r/gm, '<br />');
        },

        removeBrAfterUl(text){
            return text.replace('</ul><br />', '</ul>');
        },

        show() {
           this.visible = true
        },

        getAssetUrl(path){
            return `${path}`
        },

    }
}