import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'home-lulo-x',
    mixins: [SectionMixin],
    props: ["content"],
    data: () => {
        return {
            visible: false,
        }
    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}
