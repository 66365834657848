<template>
  <div class="snacks-view">
    <navigation ref="navigation" />
    <modal-cookies ref="modal-cookies"/>
    <header-section
        class="snacks"
        :title="content.heading"
        :subtitle="content.subHeading"
    />
    <snack-cover
        :imgUrl="content.cover.data.attributes.ext == '.jpg' || content.cover.data.attributes.ext == '.png' ? getAssetUrl(content.cover.data.attributes.url) : null"
        :videoUrl="content.videoCover.data ? getAssetUrl(content.videoCover.data.attributes.url) : null"
    />
    <snack-publisher
        v-for="(c,i) in content.publisher_profiles.data" :key="i"
        :name="c.attributes.name"
        :readingTime="content.content.readingTime"
        :imgUrl="getAssetUrl(c.attributes.avatar.data.attributes.url)"
    />
    <text-wysiwyg
        class="snacks" 
        :content="content.content.text"
    /> 
    <footer-section/>
  </div>
</template>

<script>

// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components About
import Navigation from "@/components/navigation"
import ModalCookies from "@/components/global/ModalCookies"
import Footer from "@/components/global/footer"
import Header from "@/components/global/header"
import snackCover from "@/components/snack/snack-cover"
import snackPublisher from "@/components/snack/snack-publisher"
import TextWysiwyg from "@/components/global/text-wysiwyg"


export default {
    name: 'snacks',
    mixins: [ViewMixin],
    data: ()=>{
        return {
            visible: true,

            title: String,
            description: String,
            ogTitle: String,
            ogDescription: String,
            ogImage: String,
            ogImageAlt: String,
            ogUrl: String,
        }
    },
    components: {
        'navigation': Navigation,
        'modal-cookies': ModalCookies,
        'footer-section': Footer,
        'header-section': Header,
        'snack-cover': snackCover,
        'snack-publisher': snackPublisher,
        'text-wysiwyg': TextWysiwyg,
    },

    metaInfo() {
        return{
        title: this.title,
        meta: [
            { vmid: 'description', name: 'description', content: this.description },
            { vmid: 'og:title', property: 'og:title', name: 'twitter:title', content: this.ogTitle },
            { vmid: 'og:description', property: 'og:description', name: 'twitter:description', content: this.ogDescription },
            { vmid: 'og:image', property: 'og:image', name: 'twitter:image', itemprop:'image', content: this.ogImage },
            { vmid: 'og:image:secure_url', property: 'og:image:secure_url', name: 'twitter:image', itemprop:'image', content: this.ogImage },
            { vmid: 'og:image:alt',  property: 'og:image:alt', content: this.ogImageAlt },
            { vmid: 'og:url', property: 'og:url', content: this.ogImageUrl },
        ]
        }
    },

    beforeMount() {
        if(this.content.SEO){
            this.title = this.content.SEO.title
            this.description = this.content.SEO.description
            this.ogTitle = this.content.SEO.meta[0].content
            this.ogDescription = this.content.SEO.meta[1].content
            this.ogImage = this.content.SEO.meta[2].content
            this.ogImageAlt = this.content.SEO.meta[3].content
            this.ogImageUrl = this.content.SEO.meta[4].content
        }
    },

    mounted () {
        this.show()
        this.$refs.navigation.activate("/snacks")
    },

    beforeDestroy () {
        this.$refs.navigation.forcedLight = false
    },
  
    methods: {
        show(){
            this.$refs.navigation.hidden = false
            this.$refs.navigation.forcedLight = true
        }
    },
    computed:{
        content(){
            return this.$store.state.page.snackss[this.$route.params.id].attributes
        }
    }
}
</script>