<template>
    <div>
        <form ref="form" class="form" :class="{hidden: !visible}" action="">
            <input type="hidden" ref="_token" name="_token" v-bind:value="csrf">
            <div class="split-group split-group--row">
                <div class="split-group-box">
                    <input v-model="valueName" ref="register-name" name="register-name" :class="{error: showErrorName | showErrorNameSecondary}" :placeholder="wording.form.name" type="text" required>
                    <span class="form-error compact" :class="{error: showErrorName}">{{wording.feedbacks.wrong_name}}</span>
                    <span class="form-error compact" :class="{error: showErrorNameSecondary}">{{wording.feedbacks.wrong_name_secondary}}</span>
                </div>
                <div class="split-group-box">
                    <input v-model="valueEmail" ref="register-mail" name="register-mail" :class="{error: showErrorMail}" :placeholder="wording.form.email" type="email" required>
                    <span class="form-error compact" :class="{error: showErrorMail}">{{wording.feedbacks.wrong_email}}</span>
                </div>
                <div class="split-group-box">
                    <input v-model="valuePhone" ref="register-phone" name="register-phone" :class="{error: showErrorPhone}" :placeholder="wording.form.phone" type="number" required>
                    <span class="form-error compact" :class="{error: showErrorPhone}">{{wording.feedbacks.wrong_phone}}</span>
                </div>
            </div>
            <div class="v-autocomplete-box" data-app>
                <v-autocomplete
                    v-model="valueAutocomplete"
                    ref="valueAutocomplete"
                    :class="{error: showErrorCity}"
                    :items="colombiaCities"
                    cache-items
                    clearable
                    solo
                    :attach="true"
                    :item-text="getItemText"
                    :item-value="getItemDaneCode"
                    :label="wording.form.autocomplete_label"
                    :no-data-text="wording.form.autocomplete_error"
                    @change="saveAutocomplete"
                ></v-autocomplete>
                <span class="form-error compact" :class="{error: showErrorCity}">{{wording.feedbacks.wrong_city}}</span>
            </div>
            <div class="split-group split-group--overlap">
                <div class="split-group-box">
                    <div class="select-box">
                        <span class="decoration-arrow"></span>
                        <select v-model="valueCardType" ref="register-id-type" :class="{error: showErrorSelect}" required>
                            <option v-for="option in selectOptions" v-bind:value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="split-group-box">
                    <input v-model="valueCard" ref="register-id-card" name="register-id-card" :class="{error: showErrorIdCard | showErrorIdCardPhone | showErrorIdCardReepeted}" :placeholder="wording.form.id_card" type="number" required>
                    <span class="form-error compact" :class="{error: showErrorIdCard}">{{wording.feedbacks.wrong_id_card}}</span>
                    <span class="form-error compact" :class="{error: showErrorIdCardPhone}">{{wording.feedbacks.wrong_id_card_phone}}</span>
                    <span class="form-error compact" :class="{error: showErrorIdCardReepeted}">{{wording.feedbacks.reepeted_id_card}}</span>
                </div>
            </div>

            <div data-app>
                <v-dialog
                attach="#app"
                :close-on-content-click="false"
                ref="menuPickerTwo"
                v-model="menuPickerTwo"
                transition="scale-transition"
                width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="valueDateTwo"
                    :class="{error: showErrorDateTwo}"
                    locale="es"
                    :label="wording.form.date_of_expedition"
                    :placeholder="wording.form.date_of_birth_placeholder"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    ref="register-date_of_expedition"
                    v-model="valueDateTwo"
                    :active-picker.sync="activePickerTwo"
                    locale="es"
                    :max="rangeDate(0)"
                    :min="rangeDate(82)"
                    @change="saveDateTwo"
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menuPickerTwo = false"
                >
                Cerrar
                </v-btn>
                </v-date-picker>
                </v-dialog>
                <span class="form-error compact" :class="{error: showErrorDateTwo}">{{wording.feedbacks.wrong_date_of_expedition}}</span>
            </div>

            <div data-app>
                <v-dialog
                attach="#app"
                :close-on-content-click="false"
                ref="menuPicker"
                v-model="menuPicker"
                transition="scale-transition"
                width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="valueDate"
                    :class="{error: showErrorDate}"
                    locale="es"
                    :label="wording.form.date_of_birth"
                    :placeholder="wording.form.date_of_birth_placeholder"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    ref="register-date_of_birth"
                    v-model="valueDate"
                    :active-picker.sync="activePicker"
                    locale="es"
                    :max="rangeDate(21)"
                    :min="rangeDate(100)"
                    @change="saveDate"
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menuPicker = false"
                >
                Cerrar
                </v-btn>
                </v-date-picker>
                </v-dialog>
                <span class="form-error compact" :class="{error: showErrorDate}">{{wording.feedbacks.wrong_date_of_birth}}</span>
            </div>

            <label class="form-checkbox-container">
                {{wording.form.intro_privacy_notice}}<a class="form-checkbox-text" :href="wording.form.link_privacy_notice" target="_blank">{{wording.form.privacy_notice}}</a>
                <input v-model="checked" ref="register-checkbox" class="form-checkbox" type="checkbox" required />
                <span class="form-checkbox-checkmark"></span>
            </label>

            <button><span>{{wording.buttons.send}}</span></button>
        
        </form>
    </div>
</template>

<script>

import Regex from "@/js/utils/regex"
import axios from 'axios'
import uuidAPIKey from 'uuid-apikey'
import '@/js/tagging/facebookPixel'
import '@/js/tagging/twitterPixel'
import jsonCities from '@/assets/colombia-cities-dane.json';

export default {
    name: 'credit-form',
    data: () => {
        return {
            colombiaCities: jsonCities.data,
            localSearch: null,
            valueAutocomplete: null,

            csrf: null,
            valid: false,

            activePicker: null,
            activePickerTwo: null,
            menuPicker: false,
            menuPickerTwo: false,
            overlay: false,

            showErrorName: false,
            showErrorNameSecondary: false,
            showErrorMail: false,
            showErrorPhone: false,
            showErrorSelect: false,
            showErrorIdCard: false,
            showErrorIdCardPhone: false,
            showErrorIdCardReepeted: false,
            showErrorCity: false,
            showErrorDate: false,
            showErrorDateTwo: false,
            checkboxValid: false,

            valueName: null,
            valueCard: null,
            valueCardType: 'Cédula de ciudadanía',
            selectOptions: [
                { text: 'CC', value: 'Cédula de ciudadanía' },
                { text: 'CE', value: 'Cédula de extranjería' }
            ],
            valueEmail: null,
            valuePhone: null,
            valueDate: null,
            valueDateTwo: null,
            pageUrl: null,
            checked: false,

            visible: false,
            isSending: false,
        }
    },
    watch: {
        menuPicker (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        menuPickerTwo (val) {
            val && setTimeout(() => (this.activePickerTwo = 'YEAR'))
        },
    },
    mounted() {


        this.$refs["register-name"].addEventListener("change", ()=>{
            this.onChange("register-name")
        })
        this.$refs["register-id-card"].addEventListener("change", ()=>{
            this.onChange("register-id-card")
        })
        this.$refs["register-mail"].addEventListener("change", ()=>{
            this.onChange("register-mail")
        })
        this.$refs["register-phone"].addEventListener("change", ()=>{
            this.onChange("register-phone")
        })
        this.$refs["register-id-type"].addEventListener("change", ()=>{
            this.onChange("register-id-type")
        })
        if (this.$refs["register-checkbox"]) {
            this.$refs["register-checkbox"].addEventListener("change", ()=>{
                this.onChange("register-checkbox")
            })
        }

        this.$el.addEventListener("submit", (evt)=>{
            evt.preventDefault()
            this.onSubmit()
        /*  this.isSending = true */
        })

    },

    beforeMount() {
        this.wording = this.$store.state.wording.data.attributes
        this.getCsrfToken().then(t => {
            this.csrf = t;
        })
    },

    methods: {
        getItemText(data) {
            return `${data.city},  ${data.department}`;
        },
        getItemDaneCode(data){
            return `${data.dane}`;
        },
        rangeDate(day) {
            const date = new Date();
            date.setFullYear(date.getFullYear() - day);
            return date.toISOString().substr(0, 10);
        },
        saveDate(date) {
            this.$refs.menuPicker.save(date);
            this.showErrorDate = false
        },
        saveDateTwo(date) {
            this.$refs.menuPickerTwo.save(date);
            this.showErrorDateTwo = false
        },
        saveAutocomplete(){
            if(!this.valueAutocomplete){
                this.showErrorCity = true
            }else{
                this.showErrorCity = false
            }
        },

        onChange(id){

            if (id == "register-name") {

                let nameValue = this.$refs["register-name"].value
                let regexSpaces = (/^\s+|\s+$/g)
                let nameResult = nameValue.replace(regexSpaces, "")
                this.$refs["register-name"].value = nameResult

                this.showErrorName = !Regex.name.test(this.$refs["register-name"].value)
                this.showErrorNameSecondary = !Regex.nameSecondary.test(this.$refs["register-name"].value)
            } 
            else if (id == "register-id-card") {
                this.showErrorIdCard = !Regex.idCard.test(this.$refs["register-id-card"].value)
                this.showErrorIdCardPhone = Regex.idCardPhoneNumber.test(this.$refs["register-id-card"].value)
                this.showErrorIdCardReepeted = false
            }
            else if (id == "register-mail") {
                this.showErrorMail = !Regex.email.test(this.$refs["register-mail"].value)
            }
            else if (id == "register-phone") {
                this.showErrorPhone = !Regex.phone.test(this.$refs["register-phone"].value)
            }
            else if (id == "register-id-type") {
                this.showErrorSelect = !Regex.idType.test(this.$refs["register-id-type"].value)
            }
            else if (id == "register-checkbox") {
                this.checkboxValid = this.$refs["register-checkbox"].checked
            }
        },

        getCsrfToken: async () => {
            const token = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/csrf-tokens?populate=deep`);
            return uuidAPIKey.toUUID(token.data.token.toUpperCase());
        },

        sendData: async ( name, mail, phone, card, bday, utm, accept, cardtype, expedition,citydane , token)=>{
            const { data } = await axios
            .post(`${process.env.VUE_APP_API_ENDPOINT}/api/credit-list-records`, {
            "data": {
                user_name: name,
                email: mail,
                phone: phone,
                id_card: card,
                date_of_birth: bday,
                utm: utm,
                legal_terms: accept,
                card_type: cardtype,
                date_of_expedition: expedition,
                city: citydane,
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": `Bearer ${view.$router.TOKEN}`
                }
                },
            },{
                headers:{
                    "Anti-Csrf-Token": token
                    }
                })
            return data
        },

        checkForm(){
            if (!this.showErrorName &&
                !this.showErrorNameSecondary && 
                !this.showErrorSelect &&
                !this.showErrorIdCard &&
                !this.showErrorIdCardPhone && 
                !this.showErrorMail &&
                !this.showErrorPhone &&
                !this.showErrorCity &&
                this.checkboxValid) {
                return true 
            }
        },
        validForm(){
            if (this.valueName && 
                this.valueCardType &&
                this.valueCard && 
                this.valueEmail && 
                this.valuePhone &&
                this.valueAutocomplete && 
                this.valueDate &&
                this.valueDateTwo) {
                this.showErrorDate = false
                this.showErrorDateTwo = false
                this.showErrorCity = false
                return true 
            }else if(!this.valueDate){
                this.showErrorDate = true
            }else if(!this.valueDateTwo){
                this.showErrorDateTwo = true
            }else if(!this.valueAutocomplete){
                this.showErrorCity = true
            }
        },
        cleanForm(){
            this.valueName = ""
            this.valueCard = ""
            this.valueEmail = ""
            this.valuePhone = ""
            this.valueDate = ""
            this.valueDateTwo = ""
            this.valueAutocomplete = ""
            this.checked = false
            this.showErrorIdCardReepeted = false
        },
        
        fullPageUrl(){
            let fullUrl = window.location.href;
            return fullUrl;
        },

        gtagEvents() {
            const gtag = function() {
                dataLayer.push(arguments);
                }
            gtag('event', 'success', {
                'send_to': 'G-TV4E02JMTW',
                'web_form_success': 'true',
                });
            gtag('event', 'conversion', {
                'send_to': 'AW-597546627/ozDRCO7X9O8CEIOt95wC',
            });
        },

        onSubmit(){

            this.onChange()

            if ( this.checkForm() && this.validForm() ) {

                let fullUrlValue = this.fullPageUrl()

                this.sendData(  this.$refs["register-name"].value,
                                this.$refs["register-mail"].value,
                                this.$refs["register-phone"].value,
                                this.$refs["register-id-card"].value,
                                this.valueDate,
                                this.pageUrl = fullUrlValue,
                                this.checked,
                                this.valueCardType,
                                this.valueDateTwo,
                                this.valueAutocomplete,
                                this.$refs["_token"].value
                                ).then((data)=>{
                    if (data) {
                        window.APP.$refs.modal.showMessage(this.wording.feedbacks.information_saved, false)
                        this.gtagEvents() // gtag success to G.Analytics & gtag conversion to G.Ads.
                        fbq('track', 'Lead'); // facebookPixel Lead event.
                        twq('event', 'tw-o9gts-o9gtz'); // Twitter Lead event.
                        setTimeout(() => {
                            this.cleanForm()
                            this.getCsrfToken().then(t => {
                                this.csrf = t;
                                })
                        }, 4000);
                    }
                }).catch((error) =>{
                    if ( error.response ) {//Out for 2xx
                        if ( error.response.status === 400 ){
                            setTimeout(() => {
                                this.showErrorIdCardReepeted = true
                            }, 500);
                        }else if ( error.response.status === 500 ){
                            window.APP.$refs.modal.showMessage("Error: 500", true)
                        }
                        console.log("status",error.response.status);
                    } else if ( error.request ) {
                        console.log("Error request: ",error.request);
                    } else {
                        console.log('Error: ', error.message);
                    }
              });
                setTimeout(() => {
                    this.getCsrfToken().then(t => {
                        this.csrf = t;
                        })
                }, 2000);
            }
        }
    }
}

</script>

<style lang="less" scoped>
    .form{
        position: relative;
        text-align: left;
        z-index: 1;
    }
    .is_open{
        max-height: 100vh;
        transition: max-height 1s, opacity 0.5s 0.2s;  
        opacity: 1;
    }
    .is_closed {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }
</style>