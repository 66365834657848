export default {
    pageUrl(){
        let url = window.location.pathname;

        switch (true){
            case (url === '/'):
            return 'web_home'
            
            case (url === '/waiting-list'):
            return 'web_waiting_list'
            
            case (url === '/about'):
            return 'web_about_us'
            
            case (url === '/features/ahorra-tu-plata'):
            return 'web_feat_save_money'
            
            case (url === '/features/usa-tu-plata'):
            return 'web_feat_use_money'
            
            case (url === '/features/usa-tu-app'):
            return 'web_feat_use_app'
            
            case (url === '/features/pide-tu-credito'):
            return 'web_feat_use_credit'

            case (url.indexOf('legals') === 1):
            return 'web_feat_docs_legal'

            case (url === '/snacks'):
            return 'web_feat_snacks_home'

            case (url.indexOf('snacks') === 1):
            return 'web_feat_snacks'
            
            case (url === '/404'):
            return 'web_error_404'

            default :
            return 'unknown'
        }
    },

    fullPageUrl(){
        let fullUrl = window.location.href;
        return fullUrl;
    }
}