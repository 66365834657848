<template>
    <div class="snack-filter-box container" v-bind:class="'snack-filter-box--'+[selected]">
        <span class="snack-filter-item main-item icon-box">
            <svg class="logo" data-name="lulo_logo_picto" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" x="0" y="0">
            <path class="minimal-picto" d="M8.9115 13.1465H4.67788H0.441406V8.91003V4.67356V0.439941H4.67788H8.9115V4.67356H4.67788V8.91003H8.9115V4.67356H13.148V8.91003V13.1465H8.9115Z" fill="#FCFBF2"/>
            </svg>
        </span>  
        <button type="button" class="snack-filter-item main-item" v-on:click="filterElement('all'), selected = 'all'">snacks</button>
        <button type="button" class="snack-filter-item" v-on:click="filterElement('plata'), selected = 'plata'" :class="{active:selected === 'plata'}">plata</button>
        <button type="button" class="snack-filter-item" v-on:click="filterElement('banco'), selected = 'banco'" :class="{active:selected === 'banco'}">banco</button>
        <!-- <button type="button" class="snack-filter-item" v-on:click="filterElement('actualidad'), selected = 'actualidad'" :class="{active:selected === 'actualidad'}">actualidad</button> -->
    </div>
</template>

<script>
export default {
    name: 'snack-filter',
    data: ()=>{
        return{
            cardItems: '',
            selected: 'all',
        }
    },
    mounted () {
        this.cardItems = document.getElementsByClassName('snack-card-block')
    },
    methods:{
        filterElement(type){
            Array.from(this.cardItems).forEach(element => {
            let itemAttribute = element.getAttribute('data-name')

                if(itemAttribute === type && type != 'all'){
                    element.removeAttribute("hidden");             
                }else{
                    element.setAttribute("hidden","");
                }
                if(type === 'all'){
                    element.removeAttribute("hidden");
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
    @import "../../../styles/vars.less";
    .snack-filter-box{
        position: relative;
        display: flex;
        padding: 162px 10px 42px;
        z-index: 1;

        &--plata{
           .snack-filter-item.main-item{
               background: @lavander;
           } 
        }
        &--banco{
           .snack-filter-item.main-item{
               background: @tangerine;
           } 
        }
        &--actualidad{
           .snack-filter-item.main-item{
               background: @sky-blue;
           } 
        }
    }
    .snack-filter-item{
        background: @white;
        padding: 8px 24px;
        margin: 0 0 0 14px;
        font-size: 1.188em;
        height: 42px;
        order:3;
        transition: color 0.3s @custom-ease-1,
                    background-color 0.3s @custom-ease-1,
                    order 1s @custom-ease-2,
                    margin-left 1s @custom-ease-2;

        &:before,&::after{
            content: none;
        }
    }
    .snack-filter-item{
        &.icon-box{
            padding: initial;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            min-width: 42px;
            border-radius: 22px;
        }
    }
    .snack-filter-item{
        &.main-item{
            color: @white;
            order:1;
            margin: 0;
            background: @blue-dark;
        }
        &:hover{
            color: @white;
            background: @blue-light;
        }
        &.active{
            margin: 0;
        }
        &.active:nth-child(3){
            background: @lavander;
            color: @white;
            order:2;
        }
        &.active:nth-child(4){
            background: @tangerine;
            color: @white;
            order:2;
        }
        &.active:nth-child(5){
            background: @sky-blue;
            color: @white;
            order:2;
        }
    }
    @media screen and (max-width: @laptop) {
        .snack-filter-box{
            padding: 162px 70px 42px;
        }
    }
    @media screen and (max-width: @phone) {
        .snack-filter-box{
            overflow: hidden;
            padding: 100px 12px 60px;
        }
        .snack-filter-item{
            font-size: 1em;
        }
    }
</style>