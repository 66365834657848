import SectionMixin from '@/mixins/sectionMixin'
import TextWysiwyg from "@/components/global/text-wysiwyg"


export default {
    name: 'feature-splited-image-block',
    mixins: [SectionMixin],
    components: {
       'text-wysiwyg': TextWysiwyg,
    },
    props: {
        number: String,
        imgUrl: String,
        videoUrl: String,
        title: String,
        description: String,
        titleBgColor: String,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}