import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    header: {},
    footer: {},
    wording: {},
    page: {},
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
