import PostProcessRT from '../postProcess/postProcessRT'
import Rendable from '@/glxp/abstract/rendable'
import Shader from '@/glxp/utils/shader'
import Mouse from '@/js/utils/mouse'

import ShaderManifest from '../shaderManifest'
import DebugController from '@/glxp/debug/debugController'
import {TweenMax} from "gsap"

import {vec2} from 'gl-matrix'
import { setTimeout } from 'core-js'

const VERTICES = [-1, 1, 0, -1, -1, 0, 1, -1, 0, 1, 1, 0]
const INDICES = [0, 1, 2, 0, 2, 3]
const UVS = [0, 1, 0, 0, 1, 0, 1, 1]

class Pass extends Rendable {
    constructor(scene, parent) {
        super(scene)
        this.hasNormal = false

        this.parent = parent
        this.direction = [0, 0]

        var shader = new Shader(ShaderManifest['background'], 2)

        this.initProgram(shader.vert, shader.passes[0])
        this.initBuffer({
            vertices: VERTICES,
            uvs: UVS,
            indices: INDICES
        })
        this.initVao()
        this.createUniforms()

        this.activeRt = 0
        this.disabledRt = 1

        this.rts = [
            new PostProcessRT(this.scene, 0.2),
            new PostProcessRT(this.scene, 0.2)
        ]

    }

    createUniforms() {
        this.createUniform('uTexture', 'texture')
        this.createUniform('uRez', 'float2')
        this.createUniform('uMouse', 'float2')
        this.createUniform('uFade')
        this.createUniform('uSpeed')
        this.createUniform('uRadius')
        this.createUniform('uBorderSize')
    }

    applyState() {
        let gl = this.gl
        this.scene.applyDefaultState()
        gl.disable(gl.DEPTH_TEST)
    }

    preRender() {
        this.rts[this.disabledRt].preRender()
    }

    postRender() {
        this.rts[this.disabledRt].postRender()
        this.activeRt = this.activeRt === 1 ? 0 : 1
        this.disabledRt = this.disabledRt === 1 ? 0 : 1
    }

    bind() {
        this.rts[this.activeRt].bind()
    }

    render() {
        let gl = this.gl


        gl.useProgram(this.program)
        gl.bindVertexArray(this.vao)
        this.applyState()

        this.preRender()

        this.bindUniform('uRez', [this.scene.width, this.scene.height])
        this.bindUniform('uMouse', [-Mouse.dampedCursor[0], Mouse.dampedCursor[1]])
        this.bindUniform('uFade', 1 - (this.parent.config.fadeSpeed.value * 0.1))
        this.bindUniform('uSpeed', vec2.length(Mouse.velocity))
        this.bindUniform('uRadius', 0)
        this.bindUniform('uBorderSize', this.parent.config.size.value)

        gl.activeTexture(gl.TEXTURE0)
        this.rts[this.activeRt].bind()
        this.bindUniform('uTexture', 0)

        gl.drawElements(gl.TRIANGLES, INDICES.length, gl.UNSIGNED_SHORT, 0)
        this.postRender()
        gl.bindVertexArray(null)
        gl.bindFramebuffer(gl.FRAMEBUFFER, null)

    }
}


class Background extends Rendable {
    constructor(scene) {
        super(scene)
        this.hasNormal = false
        // this.onlyVertices = false

        var shader = new Shader(ShaderManifest['background'], 2)

        this.initProgram(shader.vert, shader.frag)
        this.initBuffer({
            vertices: VERTICES,
            uvs: UVS,
            indices: INDICES
        })
        this.initVao()
        this.createUniforms()
        this.progress = 0
        this.tileOpacity = 0
        this.progressGradient = 0

        this.skip = false

        this.pass = new Pass(this.scene, this)

        this.config = {
            fadeSpeed: { value: 0.2, range: [0, 1] },
            size: { value: 0.16, range: [0, .5] },
            pixelSize: { value: 6.2, range: [0, 20] },
            noiseScale: { value: 27, range: [0, 100] },
            opacity: { value: .56, range: [0, 1] },
            debugProgress: { value: 0, range: [0, 1] },
            angleSlice: {value: 0, range: [0, 1]},
            lightColorBg: { value: [223, 226, 231], guiType: 'color' },
            color1: { value: [91, 92, 114], guiType: 'color' },
            color2: { value: [255, 255, 255], guiType: 'color' },
        }

        DebugController.addConfig(this.config, 'Background')

        // Mouse.on("wheel", (evt)=>{
        //     this.progress += Mouse.wheel[1]/1000
        //     this.progress = Math.min( Math.max(this.progress, 0.), 1.)
        // })

    }

    createUniforms() {
        this.createUniform('uTexture', 'texture')
        this.createUniform('uLightBackgroundColor', 'float3')
        this.createUniform('uColor1', 'float3')
        this.createUniform('uColor2', 'float3')
        this.createUniform('uRez', 'float2')
        this.createUniform('uMouse', 'float2')
        this.createUniform('uTime')
        this.createUniform('uTileSize')
        this.createUniform('uNoiseScale')
        this.createUniform('uOpacity')
        this.createUniform('uTime')
        this.createUniform('uAngle')
        this.createUniform('uProgress')
        this.createUniform('uProgressGradient')
    }

    applyState() {
        let gl = this.gl
        this.scene.applyDefaultState()
        gl.disable(gl.DEPTH_TEST)
    }

    intro(){
        if (this.skip == true) {
            return
        }
        TweenMax.to(this, {
            duration: 2,
            progressGradient: 1,
            tileOpacity: 1,
            ease: "power3.inOut"
        })
        TweenMax.to(this, {
            duration: 1,
            progress: 1,
            delay: 2,
            ease: "power4.inOut"
        })
    }

    reset(){
        this.skip = false
    }

    skipIntro(){
        TweenMax.set(this, {
            progressGradient: 1,
            tileOpacity: 1,
            progress: 1,
        })
        this.skip = true
    }

    preRender() {

    }

    postRender() {

    }

    render() {
        let gl = this.gl

        if (!this.scene.mobile) {
            this.pass.render()
        }
        this.gl.viewport(0, 0, this.scene.width, this.scene.height)

        gl.useProgram(this.program)
        gl.bindVertexArray(this.vao)
        this.applyState()

        gl.activeTexture(gl.TEXTURE0)
        this.pass.bind()
        this.bindUniform('uTexture', 0)

        this.bindUniform('uRez', [this.scene.width, this.scene.height])
        this.bindUniform('uLightBackgroundColor', [this.config.lightColorBg.value[0] / 255, this.config.lightColorBg.value[1] / 255, this.config.lightColorBg.value[2] / 255])
        this.bindUniform('uColor1', [this.config.color1.value[0] / 255, this.config.color1.value[1] / 255, this.config.color1.value[2] / 255])
        this.bindUniform('uColor2', [this.config.color2.value[0] / 255, this.config.color2.value[1] / 255, this.config.color2.value[2] / 255])
        
        this.bindUniform('uMouse', Mouse.cursor)
        this.bindUniform('uTime', this.scene.time)
        this.bindUniform('uTileSize', this.config.pixelSize.value)
        this.bindUniform('uNoiseScale', this.config.noiseScale.value)
        this.bindUniform('uOpacity', this.config.opacity.value * this.tileOpacity)
        this.bindUniform('uAngle', this.config.angleSlice.value)
        this.bindUniform('uProgress', this.progress - this.config.debugProgress.value)
        this.bindUniform('uProgressGradient', this.progressGradient)

        gl.drawElements(gl.TRIANGLES, INDICES.length, gl.UNSIGNED_SHORT, 0)
        gl.bindVertexArray(null)
    }
}

export default Background