
export default {
    name: 'home-scroll-invitation',
    data: () => {
        return {
            hidden: true,
            preventObserve: false,
            steps: [],
        }
    },

    mounted() {
        this.$refs["dots"][3].classList.add("active")
    },

    beforeDestroy() {
    },

    methods: {

        show() {
            this.hidden = false
        },

        hide() {
            this.hidden = true
        },

        onResize() {

        }
    }
}