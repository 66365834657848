import CtaBlock from '@/components/global/cta-block';
import SectionMixin from '@/mixins/sectionMixin'
import PhoneContainer from '@/components/global/phone-2D-container'

export default {
    name: 'introduction',
    extends: CtaBlock,
    mixins: [SectionMixin],
    props: ["content","mobileVideo"],
    components: {
        'phone': PhoneContainer,
    },
    data: () => {
        return {
            transparent: false,
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {
    },
    beforeDestroy() {

    },
    methods: {
        show(){
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}