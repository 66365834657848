import SectionMixin from '@/mixins/sectionMixin'
import Mouse from '@/js/utils/mouse'
import RAF from '@/js/utils/raf'


export default {
    name: 'about-philosophy',
    mixins: [SectionMixin],
    props: ['content'],
    components: {
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
            activeItem: 0,
            active: false,
            keypoints: [],
        }
    },
    mounted() {
        if (this.mobile) {
            this.visible = true
        }
        this.offset = 0
        this.offsetTarget = 0

        if (this.mobile) {
            this.$refs["about-philosophy-dots"][this.activeItem].classList.add("active")
        }

        this.$nextTick(() => {
            this.updatePos()
            window.addEventListener("resize", () => { this.updatePos() })
            RAF.suscribe('about-philosophy', this.update.bind(this))
            this.active = true
        })   
    },

    beforeMount(){
        let cards = this.content.content
        for (let index = 0; index < cards.length; index++) {
            let color = "default"
            color = index % 4 == 0 ? "dark" : color
            color = index % 4 == 2 ? "yellow" : color
            color = index % 4 == 3 ? "tangerine" : color
            this.keypoints.push(
                {
                    title: cards[index].title,
                    text: cards[index].text,
                    color
                }
            )
        }
    },
    
    beforeDestroy() {
        RAF.unsuscribe('about-philosophy')
    },

    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
            this.active = true
        },
        updatePos() {
            this.bounds = this.$el.getBoundingClientRect()
            this.vpwidth = window.innerWidth
            this.vpheight = window.innerHeight
            
            if (this.$refs["cards"].length > 2) {
                let cardWidth = this.$refs["cards"][0].getBoundingClientRect().width
                let cardLeft = this.$refs["cards"][0].getBoundingClientRect().left
                let range = (cardWidth * this.$refs["cards"].length )
                let margin = this.$refs["cards"][1].getBoundingClientRect().left - cardLeft - cardWidth
                range += margin * this.$refs["cards"].length
                range += cardLeft
                range -= this.vpwidth
                this.sliderRange = range
                this.offsetStep = cardWidth + margin
            } else {
                this.sliderRange = 0
                this.offsetStep = 0
            }  
        },
        update() {
            if (!this.active || !this.mobile) {
                return
            }

            if (Mouse.isDown) {
                this.offsetTarget += Mouse.velocity[0] * this.vpwidth * 0.9
                this.offsetTarget = Math.max(Math.min(this.offsetTarget, 0), - this.sliderRange)
            } else {
                this.offsetTarget = Math.round(this.offsetTarget / this.offsetStep) * this.offsetStep    
            }

            let activeIndex = Math.round(-this.offsetTarget / this.offsetStep)
            if (activeIndex !== this.activeItem) {
                this.activeItem = activeIndex
                if (this.mobile) {
                    for (let i = 0; i < this.$refs["about-philosophy-dots"].length; i++) {
                        this.$refs["about-philosophy-dots"][i].classList.remove("active")
                    }
                    this.$refs["about-philosophy-dots"][this.activeItem].classList.add("active")
                }
            }

            let tmp = this.offsetTarget - this.offset
            tmp *= .33
            this.offset += tmp

            this.$refs["about-philosophy-list-wrapper"].style.transform = `translate(${this.offset}px, 0)`
        }
    }
}