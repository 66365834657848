import Mouse from '@/js/utils/mouse'
import DebugController from '@/glxp/debug/debugController'
import { vec3 } from "gl-matrix"
import Node from '@/glxp/abstract/node'

const _vec3 = vec3.create()

class OrbitController {
    constructor (scene, camera){
        this.scene = scene
        this.camera = camera
        this.node = new Node()

        this.offset = vec3.fromValues(-Math.PI / 2, Math.PI, 0)
        this.offsetTarget = vec3.fromValues(-Math.PI / 2, Math.PI, 0)

        this.config = {
            camera_z: { value: 30, range: [-30, 30] },
            camera_damping: { value: 0.2, range: [0.001, 0.1] },
        }
        
        DebugController.addConfig(this.config, 'Orbit')
    }
    
    update(){

        if (Mouse.isDown) {
            this.offsetTarget[1] += Mouse.velocity[0]
            this.offsetTarget[0] += Mouse.velocity[1] * (this.scene.width/this.scene.height) * 2
        }
    
        this.offsetTarget[0] = Math.min(Math.max(this.offsetTarget[0], -Math.PI+0.1), -0.1)

        vec3.sub(_vec3, this.offsetTarget, this.offset)
        vec3.scale(_vec3, _vec3, this.config.camera_damping.value)
        vec3.add(this.offset, this.offset, _vec3)

        let r = this.config.camera_z.value
        this.node.position[0] = r * -Math.sin(this.offset[0]) * Math.sin(this.offset[1])
        this.node.position[1] = r * Math.cos(this.offset[0])
        this.node.position[2] = r * Math.sin(this.offset[0]) * Math.cos(this.offset[1])

        vec3.copy(this.camera.node.position, this.node.position)
        this.camera.node.lookAt([0, 0, 0])

    }
}

export default OrbitController