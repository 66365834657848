import SectionMixin from '@/mixins/sectionMixin'
import mParticleConfig from '@/js/tagging/mpartic.js'

import DownloadAppButton from '@/components/global/download-app-button'

export default {
    name: 'footer-section',
    mixins: [SectionMixin],
    components: {
        'download-app-button': DownloadAppButton,
    },
    data: () => {
        return {
            visible: false,
            content: {}
        }
    },

    mounted() {
        let linkItems = document.getElementsByClassName('mParticle-footer')
        if(mParticleConfig.mParticleReady){
            linkItems.forEach(element => {
                element.addEventListener("click", ()=>{
                    mParticle.logEvent( 'web_footer', mParticle.EventType.Navigation );
                })
            });
        }
    },

    beforeMount(){
        this.content = this.$store.state.footer.data.attributes
    },

    beforeDestroy() {

    },

    methods: {

        show() {
           this.visible = true
           this.$refs["download-app"].visible = true
        },

    }
}