<template>
    <div class="snacks-home-view">
    <navigation ref="navigation" />
    <snack-filter ref="snack-filter" />
    <div class="snack-content-wrapper container">
        <template v-for="list in content.snacks_grid">
            <snack-card
            v-for="item in list.snacks.data" :key="item.id"
            :class="list.snack_type"
            :type="list.snack_type"

            :slug="item.attributes.slug"
            :heading="item.attributes.heading"
            :subHeading="item.attributes.subHeading"
            :category="item.attributes.category"
            :imgUrl="item.attributes.cover.data.attributes.ext == '.jpg' || item.attributes.cover.data.attributes.ext == '.png' ? getAssetUrl(item.attributes.cover.data.attributes.url) : null"
            />
        </template>
    </div>
    <modal-cookies ref="modal-cookies"/>
    <footer-section/>
  </div>
</template>

<script>
// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components
import Navigation from "@/components/navigation"
import Footer from "@/components/global/footer"
import snackCard from "@/components/snack/snack-card"
import snackFilter from "@/components/snack/snack-filter"

// Components Global
import ModalCookies from "@/components/global/ModalCookies"

export default {
    name: 'snacks-home',
    mixins: [ViewMixin],
    data: ()=>{
        return {
            visible: true,
            content: {
                snacks: [],
            }
        }
    },
    components: {
        'navigation': Navigation,
        'modal-cookies': ModalCookies,
        'footer-section': Footer,
        'snack-card': snackCard,
        'snack-filter': snackFilter
    },
    beforeMount() {
        this.content = this.$store.state.page["snacks-home"].data.attributes
        this.wording = this.$store.state.wording.data.attributes
    },
    mounted () {
        this.$nextTick(()=>{
                this.show()
                this.$refs.navigation.activate("/snacks")
            })
        this.cardItems = document.getElementsByClassName('snack-card-block')
    },
    methods: {
        show(){
            this.$refs.navigation.hidden = false
            this.$refs.navigation.forcedLight = true
        }
    }
}
</script>

<style lang="less" scoped>
    @import "../styles/vars.less";
    .snacks-home-view{
        background: @grey;
    }
    .snack-content-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: @tablet) {
        .snack-content-wrapper{
            max-width: inherit;
            grid-template-columns: 1fr;
        }
    }
</style>