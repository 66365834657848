import SectionMixin from '@/mixins/sectionMixin'
import DownloadAppButton from '@/components/global/download-app-button'
import PhoneContainer from '@/components/global/phone-2D-container'

export default {
    name: 'introduction',
    mixins: [SectionMixin],
    props: ["content","mobileVideo"],
    components: {
        "download-app-button": DownloadAppButton,
        'phone': PhoneContainer,
    },
    data: () => {
        return {
            transparent: false,
            isIntersecting: false,
            visible: false,
            lastPhoneVideoStep: 0,
        }
    },
    mounted() {
    },
    beforeDestroy() {

    },
    methods: {
        show(){
            if (this.visible) {
                return
            }
            this.visible = true
            this.$refs["download-app"].visible = true
        },
        progress(p) {
            let phoneVideoStep = Math.min(Math.floor(p.before * 2), 1)
            if (phoneVideoStep !== this.lastPhoneVideoStep) {
                this.lastPhoneVideoStep = phoneVideoStep
                if (this.$root.phone) {
                    this.$root.phone.gotToSlide(phoneVideoStep)
                }
            }
            if (window.scrollY > 150) {
                this.$parent.$refs["scroll-invitation"].hide()
            }
        },
    }
}