<template>
    <div class="transition-panel" :class="{hidden: !visible, left}">
    </div>
</template>

<script>
    export default {
        name: 'transition-panel',
        data: ()=>{
            return {
                visible: false,
                left: false,
            }
        },
        mounted() {
        },
        methods: {
            show(){
                this.left = false
                this.visible = false
                this.$nextTick(()=>{
                    this.visible = true
                })
            },
            hide(){
                this.left = true
                setTimeout(() => {
                    this.visible = false
                    this.$nextTick(()=>{
                        this.left = false
                    })
                }, 1000);
            },
        }
    }
</script>