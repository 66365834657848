import WebglManager from '@/glxp/webglManager'
import Manifest from "@/glxp/manifest"

export default {
    name: 'phone-container',
    props:[
        "contents",
        "color",
    ],
    data: () => {
        return {
            hidden: true,
            blur: false,
            background: false,
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.glxp = WebglManager.getScene('main')
            this.glxp.on("loaded", () => {
                setTimeout(() => {
                    this.init()
                }, 2000);
            })
        })
    },

    created(){
        this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },

    beforeMount() {
        
    },

    beforeDestroy() {

    },

    methods: {

        init(){
            if (this.$refs.background == undefined) {
                return
            }
            let bgImg = new Image()
            bgImg.src = this.color == "black" ? 
                Manifest.home.phoneShinyBlackColor.url : 
                Manifest.home.phoneBlackColor.url;

            this.$nextTick(()=>{
                this.$refs.background.appendChild(bgImg)
                bgImg.setAttribute("alt","phone background mask frame")
                this.$refs["main-container"].style.maskImage = `url(${this.glxp.textureLoader.getImage('phoneMask').src})`
                this.$refs["main-container"].style.webkitMaskImage = `url(${this.glxp.textureLoader.getImage('phoneMask').src})`
            })
        },

    }
}