import Emitter from 'event-emitter'
import RAF from '@/js/utils/raf'
import SectionMixin from '@/mixins/sectionMixin'
import Mouse from '@/js/utils/mouse'
import TextWysiwyg from '@/components/global/text-wysiwyg'


export default {
    name: 'slider',
    mixins: [SectionMixin],
    components: {
        'text-wysiwyg': TextWysiwyg,
    },
    props: {
        titled: {
            type: Boolean,
            default: true,
        },
        items: {
            type: Array
        },
        title: {
            type: String
        },
    },
    watch: {
        isIntersecting: function (val) {
            if (val) {
                this.onVPIn()
            } else {
                this.onVPOut()
            }
        },
    },
    data: () => {
        return {
            visible: false,
            active: false,
            isIntersecting: false,
            activeItem: 0,
            hideButtons: false,
            blockOffset: false,
        }
    },
    mounted() {

        this.offset = 0
        this.offsetTarget = 0
        this.dragActive = true
        
        if (this.mobile) {
            this.$refs["slider-dots"][this.activeItem].classList.add("active")
            this.dragActive = false
            this.$refs["slider-card-container"].addEventListener("touchstart", ()=>{
                this.dragActive = true
            })
        }

        this.$nextTick(() => {
            this.id = generateId(20)
            if (this.items.length > 3 || this.mobile) {
                this.updatePos()
                if (!this.mobile) {
                    window.addEventListener("resize", () => { this.updatePos() })
                }
                RAF.suscribe('slider-' + this.id, this.update.bind(this))
            }
        })   

        if (this.items.length > 3 || this.mobile) {
            this.upTimeout = setTimeout(() => {}, 0);
            Mouse.on('up', ()=>{
                clearTimeout(this.upTimeout)
                if (!this.mobile) {
                    this.upTimeout = setTimeout(() => {
                        this.offsetTarget = Math.round(this.offsetTarget / this.offsetStep) * this.offsetStep
                    }, 500)
                } else {
                    this.offsetTarget = Math.round(this.offsetTarget / this.offsetStep) * this.offsetStep
                    this.upTimeout = setTimeout(() => {
                        this.dragActive = false
                    }, 500)
                }
            })
            Mouse.on('down', ()=>{
                clearTimeout(this.upTimeout)
            })
        } else {
            this.hideButtons = true
        }

    },

    beforeDestroy() {
        RAF.unsuscribe('slider-' + this.id, this.update.bind(this))
    },
    
    methods: {
        show() {
            this.visible = true
        },
        imageFormat(rawFormat){
            switch (true) {
                case (rawFormat === '.png'):
                    return 1
                case (rawFormat === '.jpg'):
                    return 1
                case (rawFormat === '.jpeg'):
                    return 1
                case (rawFormat === '.svg'):
                    return 1
                case (rawFormat === '.gif'):
                    return 1
                default:
                    break;
            }
        },
        videoFormat(rawFormat){
            switch (true) {
                case (rawFormat === '.mp4'):
                    return 1
                case (rawFormat === '.mpeg'):
                    return 1
                default:
                    break;
            }
        },

        updatePos() {

            this.bounds = this.$el.getBoundingClientRect()
            this.vpwidth = window.innerWidth
            this.vpheight = window.innerHeight
            
            if (this.$refs["cards"].length > 3 || this.mobile) {
                let cardWidth = this.$refs["cards"][0].getBoundingClientRect().width
                let cardLeft = this.$refs["cards"][0].getBoundingClientRect().left
                let range = (cardWidth * this.$refs["cards"].length - 1)
                let margin = this.$refs["cards"][1].getBoundingClientRect().left - cardLeft - cardWidth
                range += margin * this.$refs["cards"].length
                range += cardLeft
                range -= this.vpwidth
                this.sliderRange = range
                this.offsetStep = cardWidth + margin
            } else {
                this.sliderRange = 0
                this.offsetStep = 0
            }  

            if (this.sliderRange == 0) {
                this.hideButtons = true
            }

        },
        
        onVPIn() {
            this.active = true
        },
        
        onVPOut() {
            this.active = false
        },

        slide(dir){
            if (dir == "left") {
                this.offsetTarget -= this.offsetStep
            } else {
                this.offsetTarget += this.offsetStep
            }
            this.offsetTarget = Math.max(Math.min(this.offsetTarget, 0), - this.sliderRange)
        },

        update() {
            if (!this.active || !this.mobile) {
                return
            }

            if (Mouse.isDown) {
                this.offsetTarget += this.mobile ? Mouse.velocity[0] * this.vpwidth * 1.1 : Mouse.velocity[0] * this.vpwidth * 0.8
                this.offsetTarget = Math.max(Math.min(this.offsetTarget, 0), - this.sliderRange)
            }else {
                this.offsetTarget = Math.round(this.offsetTarget / this.offsetStep) * this.offsetStep    
            }

            if (!this.dragActive) {
                return
            }

            let activeIndex = Math.round(-this.offsetTarget / this.offsetStep)
            if (activeIndex !== this.activeItem) {
                this.activeItem = activeIndex
                if (this.mobile) {
                    for (let i = 0; i < this.$refs["slider-dots"].length; i++) {
                        this.$refs["slider-dots"][i].classList.remove("active")
                    }
                    this.$refs["slider-dots"][this.activeItem].classList.add("active")
                }
            }

            let tmp = this.offsetTarget - this.offset
            tmp *= .25
            this.offset += tmp

            this.$refs["slider-card-wrapper"].style.transform = `translate(${this.offset}px, 0)`


        }
    }
}