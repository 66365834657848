import SectionMixin from '@/mixins/sectionMixin'
import PhoneContainer from '@/components/global/phone-2D-container'


export default {
    name: 'feature-phone-block',
    mixins: [SectionMixin],
    components: {
        'phone': PhoneContainer
    },
    props: {
        number: String,
        title: String,
        text: String,
        media: Object,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
            phoneMedias: [],
        }
    },
    beforeMount(){
        if (this.media.data.attributes.mime.includes("image")) {
            this.phoneMedias.push({
                image: this.getAssetUrl(this.media.data.attributes.formats.large.url)
            })
        } else if(this.media.data.attributes.mime.includes("video")){
            this.phoneMedias.push({
                video: this.getAssetUrl(this.media.data.attributes.url)
            })
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}