<template>
  <div class="legals-view">
    <navigation ref="navigation" />
    <modal-cookies ref="modal-cookies"/>
    <header-section 
        class="grey"
        :title="content.attributes.heading"
        :subtitle="content.attributes.subHeading"
    />

    <text-wysiwyg 
        class="legals"
        v-for="(c,i) in content.attributes.content" :key="i"
        :title="c.title"
        :description="c.description"
        :content="c.text"
        :date="dateFormat()"
        :imgUrl="c.cover.data ? getAssetUrl(c.cover.data.attributes.url) : null"
    />
    <footer-section/>
  </div>
</template>

<script>
// Webgl
import WebglManager from '@/glxp/webglManager'

// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components About
import Navigation from "@/components/navigation"
import ModalCookies from "@/components/global/ModalCookies"
import Footer from "@/components/global/footer"
import Header from "@/components/global/header"
import TextWysiwyg from "@/components/global/text-wysiwyg"


export default {
    name: 'privacy',
    mixins: [ViewMixin],
    data: ()=>{
        return {
            visible: true,
        }
    },
    components: {
        'navigation': Navigation,
        'modal-cookies': ModalCookies,
        'footer-section': Footer,
        'header-section': Header,
        'text-wysiwyg': TextWysiwyg,
    },

    updated() {
        this.$nextTick(() => {
            this.updateViewport()
            this.initIntersectionObserver()
        })
    },

    mounted () {
        this.$nextTick(()=>{
            this.glxp = WebglManager.getScene('main')
            this.glxp.skipIntro()
            //this.glxp.setBackgrooundColor("dark")
            WebglManager.loadScene('main').then(()=>{
                WebglManager.activate('main')
                this.show()
            })
        })
    },
  
    methods: {
        show(){
            this.$refs.navigation.hidden = false
            this.$refs.navigation.forcedLight = true
        },
        dateFormat() {
            let rawDate = this.content.attributes.updatedAt
            let date = new Date(rawDate).toLocaleDateString()
            return date
        }
    },
    computed: {
        content(){
            return this.$store.state.page.legalss[this.$route.params.id]
        }
    }
}
</script>

<style lang="less" scoped>
    @import "../styles/vars.less";

    .legals-view{
        background: @grey-mid;
    }

    .header-section {
        min-height: 0;
        @media screen and (max-width: @tablet) {
            margin-bottom: 0;
            padding-bottom: 20px
        }
    }

    .header-section-container {
        @media screen and (max-width: @tablet) {
            margin-bottom: 10px;
            padding-top: 90px;
        }
    }
</style>
