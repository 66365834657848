<template>
    <div class="home-main-banner-section observed" :class="{hidden: !visible}">
        <h1 class="home-main-banner-section-title">{{ title }}</h1>
        <p v-if="subTitle" class="home-main-banner-section-subtitle">{{ subTitle }}</p>
        <div v-if="buttonLink && buttonWording" class="button-box">
			<a v-if="!getInternalLink(buttonLink)" :href="buttonLink" class="cta cta-blue" :class="{hidden: !visible}"><span>{{ buttonWording }}</span></a>
            <router-link v-else :to="buttonLink" class="cta cta-blue" :class="{hidden: !visible}"><span>{{ buttonWording }}</span></router-link>
        </div>
    </div>
</template>

<script>

import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'home-main-banner',
    mixins: [SectionMixin],
    props: {
        title: String,
        subTitle: String,
        buttonWording: String,
        buttonLink: String,
    },
    data: () =>{
        return {
            visible: false,
        }
    },

    methods: {
        show() {
            this.visible = true
        },
    }
}
</script>

<style lang="less">
@import "../../../styles/vars.less";

.home-main-banner-section{
    position: relative;
    z-index: 1;

    min-height: 300px;
    min-height: calc(33.333vh + 84px);
    padding: 180px 12% 60px;

    text-align: center;

    background-color: @yellow;
    
    &.hidden {
        .home-main-banner-section-title,
        .home-main-banner-section-subtitle {
            opacity: 0;
            transform: translate(0, 30px);
        }
    }
    @media screen and (max-width: @tablet) {
        padding: 140px 12% 60px;
    }
    @media screen and (max-width: @phone) {
        min-height: 300px;
        min-height: calc(33.333vh + 12px);
        padding: 86px 12% 36px;
    }
}
.home-main-banner-section-title,
.home-main-banner-section-subtitle{
    color: @blue;
    line-height: 1.1em;
}
.home-main-banner-section-title{
    margin-bottom: 24px;
    font-family: @bold;
    font-size: 3.5em;

    transition: transform 1s @custom-ease-1, opacity 1s @custom-ease-1;

    @media screen and (max-width: @laptop) {
        font-size: 2.5em;
    }
    @media screen and (max-width: @tablet) {
        font-size: 2.5em;
    }
    @media screen and (max-width: @phone) {
        margin-bottom: 22px;
        font-size: 1.8em;
        line-height: 1.2em;
    }
}
.home-main-banner-section-subtitle{
    margin-bottom: 46px;
    font-family: @regular;
    font-size: 1.9em;

    transition: transform 1.4s @custom-ease-1, opacity 1.4s @custom-ease-1;

    @media screen and (max-width: @laptop) {
        font-size: 1.75em;
    }
    @media screen and (max-width: @tablet) {
        font-size: 1.4em;
    }
    @media screen and (max-width: @phone) {
        margin-bottom: 32px;
        font-size: 1.125em;
        line-height: 1.4em;
    }
}
</style>