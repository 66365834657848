import CtaBlock from '@/components/global/cta-block';
import RAF from '@/js/utils/raf'
import PhoneContainer from '@/components/global/phone-2D-container'
import mParticleConfig from '@/js/tagging/mpartic.js'

export default {
    name: 'home-cta-block-5',
    extends: CtaBlock,
    props: ["cards", "mobileVideo","description"],
    components: {
        'phone': PhoneContainer
    },
    data: () => {
        return {
            isIntersecting: false,
            progressTarget: 0,
            progressValue: 0,
            progressBeforeTarget: 0,
            progressBeforeValue: 0,
            lastPhoneVideoStep: 2,
        }
    },
    mounted() {

        this.$nextTick(() => {
            RAF.suscribe('home-cta-block-5-update', this.update.bind(this))
        })
    },

    beforeDestroy() {
        RAF.unsuscribe('home-cta-block-5-update')
    },

    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
        progress(p) {
            this.progressTarget = p.in
            this.progressBeforeTarget = p.before

            let phoneVideoStep = Math.min(Math.floor(p.before * 2), 1)
            if (phoneVideoStep !== this.lastPhoneVideoStep) {
                
                if (this.$root.phone) {
                        this.$root.phone.gotToSlide(phoneVideoStep + 2)
                }
            }
        },
        update(){ 
            if (this.$root.phone) {
                let phone = this.$root.phone
                if(this.progressTarget > 0.8){
                    phone.hidden = true
                } else {
                    phone.hidden = false
                }
            }
        },
        
        mparticleButton(eventName){
            if(mParticleConfig.mParticleReady){
                mParticle.logEvent( 'web_home_'+eventName, mParticle.EventType.Navigation );
            }
        }
    }
}