import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'download-app-button',
    mixins: [SectionMixin],
    data: () => {
        return {
            visible: false,
        }
    },
    beforeMount() {
        this.wording = this.$store.state.wording.data.attributes
    },
}