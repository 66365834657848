<template>
  <div class="correspondent-view">
        <navigation ref="navigation" />
        <correspondent-search
            :autocomplete_department_label="content.search_form.autocomplete_department_label"
            :autocomplete_city_label="content.search_form.autocomplete_city_label"
            :autocomplete_error="content.search_form.autocomplete_error"
            :button_search="content.search_form.button_search"
            :list_title="content.list_title"
            :title="content.title"
            :subtitle="content.description"
            :map_title="content.map_title"
        />
        <footer-section/>
  </div>
</template>

<script>
// Webgl
import WebglManager from '@/glxp/webglManager'

// Mixins
import ViewMixin from "@/mixins/viewMixin"

// Components
import Navigation from "@/components/navigation"
import Footer from "@/components/global/footer"
import correspondentSearch from "@/components/correspondent/correspondent-search"

export default {
    name: 'correspondent',
    mixins: [ViewMixin],
    data: ()=>{
        return {
            visible: true,
        }
    },
    components: {
        'navigation': Navigation,
        'footer-section': Footer,
        'correspondent-search': correspondentSearch,
    },
    beforeMount() {
        this.content = this.$store.state.page["correspondent"].data.attributes
    },
    mounted () {  
        
        this.$nextTick(()=>{
            this.glxp = WebglManager.getScene('main')
            WebglManager.loadScene('main').then(()=>{
                WebglManager.activate('main')
                this.glxp.skipIntro()
                this.show()
            })
        })
    },
    beforeDestroy () {
        this.$refs.navigation.forcedLight = false
    },
    methods: {
        show(){
            this.$refs.navigation.hidden = false
            this.$refs.navigation.forcedLight = true
        },
    },
}
</script>