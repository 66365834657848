import SectionMixin from '@/mixins/sectionMixin'


const NUMBER_OF_PICTURES_PER_PAGE_DESKTOP = 10
const NUMBER_OF_PICTURES_PER_PAGE_MOBILE = 3

export default {
    name: 'about-team-gallery',
    mixins: [SectionMixin],
    props: ['content'],
    components: {
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
            mobile: false,
            numberOfPages: 0,
            showMoreButton: true,
            wording: {},
            members: []
        }
    },
    mounted() {
        this.paginationItemNumber = this.mobile ? NUMBER_OF_PICTURES_PER_PAGE_MOBILE : NUMBER_OF_PICTURES_PER_PAGE_DESKTOP
        this.data = this.content.people
        this.loadPagination(0)  
        if (this.mobile ) {
            this.visible = true
        }
    },
    beforeMount() {
        this.wording = this.$store.state.wording
    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },

        loadNext(){
            if (this.numberOfPages * this.paginationItemNumber < this.data.length) {
                this.loadPagination(this.numberOfPages)
            }
        },

        onImageLoaded(i){
            let index = i
            this.$nextTick(()=>{
                this.$refs["members"][index].querySelector("img").style.transitionDelay = i * 30 +"ms"
                setTimeout(() => {
                    this.$refs["members"][index].classList.remove("hidden")
                }, 100);
            })
        },

        loadPagination(){
            for (let index = this.numberOfPages * this.paginationItemNumber; index < Math.min((this.numberOfPages + 1) * this.paginationItemNumber, this.data.length); index++) {
                const element = this.data[index];
                this.members.push({
                    name: element["name"],
                    picture: element["picture"] ? this.getAssetUrl(element["picture"].url) : "",
                    job: element["job"],
                })
            }
            this.numberOfPages ++

            if (this.members.length == this.data.length) {
                this.showMoreButton = false
            }

        }
    }
}