import RAF from '@/js/utils/raf'

export default {
    mounted: function () {
        this.$nextTick(()=>{
            this.updateViewport()
            window.addEventListener("resize", ()=>{ this.updateViewport() })
            this.initIntersectionObserver()
        })
    },

    beforeDestroy() {
        // clearInterval(this.interval)
        // RAF.unsuscribe('view-mixin-update')
        window.removeEventListener("scroll", this.scrollListener)
    },

    created(){
        this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },

    methods: {
        initIntersectionObserver(){


            // Intersection Observer
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: [0, 0.33, 0.5, 0.66, 1]
            }

            this.observedElements = []
            this.observer = new IntersectionObserver((evt)=>{
                this.onIntersect(evt)
            }, options)

            this.$nextTick(()=>{
                this.lookForObservedElement()
                this.updateBounds()
                this.scrollListener = window.addEventListener("scroll", ()=>{ this.updateObserverProgress() })
            })

        },

        updateViewport(){
            this.viewport = {
                width: window.innerWidth,
                height: window.innerHeight,
            }
        },

        updateBounds(){
            this.pageBounds = this.$el.getBoundingClientRect()
            for (let i = 0; i < this.observedElements.length; i++) {
                const element = this.observedElements[i];
                if (element.$vue && element.$vue.progress) {
                    element.bounds = element.getBoundingClientRect()
                }
            }
        },

        onIntersect(evt){
            for (let i = 0; i < evt.length; i++) {
                evt[i].target.isIntersecting = evt[i].isIntersecting
                if (evt[i].target.$vue) {
                    evt[i].target.$vue.isIntersecting = evt[i].isIntersecting
                }
                let visible = evt[i].isIntersecting && (1 - (evt[i].boundingClientRect.top / this.viewport.height) > .2)
                if (visible) {
                    if (evt[i].target.$vue && evt[i].target.$vue.show){
                        evt[i].target.$vue.show()
                    } else {
                        evt[i].target.classList.remove("hidden")
                    }
                } else {
                    if (evt[i].target.$vue && evt[i].target.$vue.hide) {
                        evt[i].target.$vue.hide()
                    }
                }
            }
        },

        lookForObservedElement(){
            this.observedElements = document.querySelectorAll('.observed')
            for (let i = 0; i < this.observedElements.length; i++) {
                this.observer.observe(this.observedElements[i]);
            }
            this.updateBounds()
        },

        updateObserverProgress(){
            let pageOffset = window.pageYOffset
            for (let i = 0; i < this.observedElements.length; i++) {
                const element = this.observedElements[i];
                if (element.isIntersecting && element.$vue && element.$vue.progress) {
                    let top = element.bounds.top - pageOffset

                    let progressBefore = 1 - (top / this.viewport.height)
                    let progressIn = -(top / element.bounds.height)

                    progressBefore = Math.min(Math.max(progressBefore, 0), 1)
                    progressIn = Math.min(Math.max(progressIn, 0), 1)

                    element.$vue.progress({
                        before: progressBefore,
                        in: progressIn,
                    })
                }
            }
        },

        getAssetUrl(path){
            if(path.startsWith('/')){
                return `${process.env.VUE_APP_API_ENDPOINT}${path}`
            }else if(path.startsWith('assets')){
                return `https://${path}`
            }else{
                return `${path}`
            }
        },
    }
}