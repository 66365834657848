export default {

  home: {
    'phoneMask': { url: `../textures/white-iphone-screen-mask.png`, options: [""] },
    'phoneBlackColor': { url: `../textures/black-iphone-border-metal.png`, options: [""] },
    'phoneShinyBlackColor': { url: `../textures/black-iphone-border-black.png`, options: [""] },
    'phoneBlackColorTransparent': { url: `../textures/white-iphone-border-black-transparent.png`, options: [""] },
  },

  phone: {
    'test': { url: `../glxp/UV_Grid.jpg`, options: ["flipY"] },
    'matcap': { url: `../glxp/iPhoneMatcap.jpg`, options: ["flipY"] },
    'photo-1': { url: `../textures/phone3d-photo-1.jpg`, options: ["flipY"] },
  },

  videos: {
    'credit-phone-video': { url: `../videos/05_App_Loan_05_B_V2.mp4` },
  }

}
