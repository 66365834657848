<template>
<div class="snack-publisher-block">
  <div class="snack-publisher-block-wrapper">
      <img v-if="imgUrl" :src="imgUrl" alt="" class="snack-publisher-block-item">
      <div class="snack-publisher-block-content">
          <p>{{name}}</p>
          <span class="snack-publisher-block-text">{{readingTime}}</span>
      </div>
  </div>
</div>
</template>

<script>

import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'snack-cover',
    mixins: [SectionMixin],
    components:{
    },
    props: {
      imgUrl: String,
      name: String,
      readingTime: String,
    },
    mounted() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}
</script>

<style lang="less" scoped>
  @import "../../../styles/vars.less";

  .snack-publisher-block{
    background: @white;
    padding: 54px 0;
  }
  .snack-publisher-block-wrapper{
    position: relative;
    max-width: 820px;
    margin: 0 auto;
    display: flex;
    padding-left: 26px;
  }
  .snack-publisher-block-item{
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }
  .snack-publisher-block-content{
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 8px;
    padding-left: 10px;
    color: @blue-dark;
  }
  .snack-publisher-block-text{
      font-size: 0.875em;
  }
</style>