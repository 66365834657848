// Libs
import when from 'when'
import Emitter from 'event-emitter'
import { vec2, vec3 } from 'gl-matrix'

// Strcutral
import SceneAbs from '../abstract/scene'
import Camera from '../utils/camera'
import TextureLoader from '../utils/textureLoaderObject'
import Manifest from '../manifest'
import Node from '../abstract/node'


// Entities
import Plane from '../entities/plane'
import Background from '../entities/background'

// Post
import DummyPost from '../postProcess/dummyPost'

// Utils
import RAF from '@/js/utils/raf'
import DebugController from '@/glxp/debug/debugController'
import Mouse from '@/js/utils/mouse'
import MeshLoader from '@/glxp/loader/binaryLoader'
import OrbitController from '@/glxp/camera/orbit'


const _VEC2 = vec2.create()

class Scene extends SceneAbs {
  constructor (container, manager = null) {
    super(container, manager)

    this.manager                      = manager

    this.cameraPositionTarget         = vec3.fromValues(0, 0, 0)
    this.cameraPosition               = vec3.fromValues(0, 0, 0)
    this.cameraDirection              = vec3.create()

    this.camera                       = new Camera(this, 30)
    
    this.clearColor                   = [0, 0, 0, 0]
    this.loaded                       = false

    this.background                   = new Background(this)
    // this.post                         = new DummyPost(this)
    // this.plane                        = new Plane(this, 'unlit')

    this.textureLoader                = new TextureLoader(this)

    this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1) 

    this.nodes = []
  }

  onLoaded () {
    this.active = true
    this._emitter.emit('loaded')
  }

  skipIntro(){
    this.background.skipIntro()
  }

setBackgrooundColor(color){
    if (color == "light") {
      this.background.progress = 1
    } else if(color == "dark") {
      this.background.progress = 0
    }
  }

  start () {
    // this.shakeFactor = .05
  }

  load () {
    let loadables = []
    for (const key in Manifest.home) {
      if (Manifest.home.hasOwnProperty(key)) {
        const element = Manifest.home[key].url
        loadables.push(this.textureLoader.load(element, key, Manifest.home[key].options))
      }
    }

    let sceneUrl
    sceneUrl = "glxp/models/babyshark"

    when.all(loadables).then(() => {
      this.onLoaded()
    })

    let percent = 0
    for (let i = 0; i < loadables.length; i++) {
      loadables[i].then(() => {
        percent++
        this._emitter.emit('progress', Math.round((percent / loadables.length) * 100))
      })
    }
  }

  applyDefaultState () {
    let gl = this.gl
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
    gl.enable(gl.BLEND)
    gl.enable(gl.DEPTH_TEST)
    // gl.disable(gl.CULL_FACE)
  }

  // Pre-render
  preRender () {

    this.camera.updateProjection(this.width / this.height)

    this.background.preRender()

  }

  // Render
  render () {

    if (!this.active) { return }

    this.time += RAF.dt / 1000
    this.dt = RAF.dt

    let gl = this.gl

    gl.clearColor(this.clearColor[0], this.clearColor[1], this.clearColor[2], this.clearColor[3])
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    gl.colorMask(true, true, true, true)

    this.preRender()
    gl.viewport(0, 0, this.width, this.height)

    // Render Time
    this.background.render()
    // this.post.preRender()
    // this.post.postRender()
    // this.post.render()

    this.postRender()

  }

  postRender () {
    this.gl.viewport(0, 0, this.width, this.height)
    // this.grain.render()
  }
}
export default Scene
