<template>
<div class="home-impact-card-section section observed" :class="{hidden: !visible}">
    <h2 v-if="content.title" class="home-impact-card-title">{{content.title}}</h2>
    <p v-if="content.subTitle" class="home-impact-card-subtitle">{{content.subTitle}}</p>
    <div class="home-impact-card-box">
        <template v-if="content.cards">
            <div class="home-impact-card-item" v-for="(item, e) in content.cards" :key="e">
                <img :src="getAssetUrl(item.image.data.attributes.url)" alt="" class="home-impact-card-item-image">
                <p class="home-impact-card-item-description"><strong>{{item.description_title}}</strong>{{item.description}}</p>
                <router-link :to="item.link" class="home-impact-card-item-link" :data-name="item.link_text">{{item.link_text}}</router-link>
            </div>
        </template>
    </div>
    <div class="home-impact-card-box-forest-path">
        <img class="home-impact-section-image" :src="homeImpactForest" alt="forest-svg">
    </div>
</div>
</template>

<script>
import SectionMixin from '@/mixins/sectionMixin'
import homeImpactForest from '@/assets/img/homeImpactForest.svg'

export default {
    name: 'home-impact-cards',
    mixins: [SectionMixin],
    props: ["content"],
    data: () => {
        return {
            visible: false,
            homeImpactForest: homeImpactForest,
        }
    },
}
</script>

<style lang="less" scoped>
@import "../../../styles/vars.less";

.home-impact-card-section{
    position: relative;
    z-index: 1;
    padding: 100px 140px;
    background-color: @blue-light;
    @media screen and (max-width: @tablet) {
        padding: 76px 20px;
    }
    @media screen and (max-width: @tablet) {
        padding: 48px 20px 64px;
    }
}
.home-impact-card-title{
    font-family: @bold;
    font-size: 2.4em;
    text-align: center;
    margin-bottom: 32px;
    padding: 0 16%;
    color: @green;
    line-height: 1.1em;
    letter-spacing: -0.006em;
    transition: transform 1s @custom-ease-1, opacity 1s @custom-ease-1;
    @media screen and (max-width: @laptop) {
        padding: 0 14%;
    }
    @media screen and (max-width: @tablet) {
        padding: 0 22px;
        font-size: 2em;
    }
}
.home-impact-card-subtitle{
    font-family: @medium;
    font-size: 1.125rem;
    text-align: center;
    color: @white;
    line-height: 1.1em;
    letter-spacing: -0.006em;
    transition: transform 1s @custom-ease-1, opacity 1s @custom-ease-1;
    
    @media screen and (max-width: @tablet) {
        padding: 0 22px;
    }
}
.home-impact-card-box{
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: 32px;
    margin-top: 60px;
    @media screen and (max-width: @tablet) {
        gap: 16px;
    }
    @media screen and (max-width: @phone) {
        grid-auto-flow: row;
        gap: 32px;
    }
}
.home-impact-card-item{
    max-width: 288px;
    padding: 16px 28px;
    background-color: @blue-backdrop;
    color: @white;
    border-radius: 10px;
    box-shadow: 0px 45px 55px -27px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: @tablet) {
        padding: 8px;
    }
    @media screen and (max-width: @phone) {
        padding: 16px 28px;
    }

    &-image{
        display: block;
        width: auto;
        height: 82px;
        margin: 30px auto 0;
    }
    &-description{
        margin: 16px 0;
        padding: 0 14px;
        text-align: center;
        color: fade(@white , 80%);
        font-size: 0.875em;
        line-height: normal;
        
        strong{
            display: block;
            font-family: @bold;
            font-size: 1rem;
            line-height: normal;
            color: @white;
            max-width: 94%;
            margin: 0 auto 16px;
        }
    }
    &-link{
        display: block;
        position: relative;
        z-index: 1;
        margin: 24px 0 28px 0;
        padding: 8px 0;
        text-align: center;
        font-size: 1em;
        font-style: normal;
        font-family: @bold;
        color: @yellow;
        transition: color 0.3s ease-in-out;
        &:hover{
            color: fade(@yellow , 70%);
        }
    }
}
.home-impact-card-box-forest-path {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
}

</style>