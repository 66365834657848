import SectionMixin from '@/mixins/sectionMixin'


export default {
    name: 'about-team',
    props: ['content'],
    mixins: [SectionMixin],
    components: {
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}