import when from 'when'

class TextureLoader {
  constructor(scene) {
    this.scene = scene
    this.gl = scene.gl
    this.textures = {}
    this.currentUnit = 0
  }

  load(url, id, options) {
    let image = new Image()
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      let texture = this.initTexture(image, options)
      this.textures[id].texture = texture
      this.textures[id].unit = this.currentUnit
      this.currentUnit++
      this.textures[id].defer.resolve(texture)
    }
    image.src = url
    this.textures[id] = {
      img: image,
      defer: when.defer()
    }
    return this.textures[id].defer.promise
  }

  initTexture(image, options) {
    let gl = this.gl
    // create
    let texture = gl.createTexture()

    // bind it to operate on it
    gl.bindTexture(gl.TEXTURE_2D, texture)
    if (options.indexOf("flipY") > -1) {
      gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true)
    } else {
      gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false)
    }
    // gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image)

    const w = image.width
    const h = image.height

    let wrap = gl.CLAMP_TO_EDGE
    if (w === h && (w && (w & (w - 1)) === 0)) {
      wrap = gl.REPEAT
    }
    if (options.indexOf('repeat') > -1) {
      wrap = gl.MIRRORED_REPEAT
    }
    if (options.indexOf('mirror') > -1) {
      wrap = gl.MIRRORED_REPEAT
    }
    if (options.indexOf('clamp') > -1) {
      wrap = gl.CLAMP_TO_EDGE
    }
    if (options.indexOf('mipmap') > -1) {
      gl.generateMipmap(gl.TEXTURE_2D)
    }

    // Set the filter wrapping and filter parameters.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, wrap)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, wrap)
    if (options.indexOf('mipmap') > -1) {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_LINEAR);
    } else {
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
    }
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)

    return texture
  }

  getTexture(id) {
    if (this.textures[id] && this.textures[id].texture) {
      return this.textures[id].texture
    } else {
      console.error("Texture not found : ", id)
    }
  }

  getImage(id) {
    if (this.textures[id].texture) {
      return this.textures[id].img
    }
    return null
  }
}

export default TextureLoader
