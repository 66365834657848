import { TweenMax } from "gsap"
import WebglManager from '@/glxp/webglManager'
import RAF from '@/js/utils/raf'
import Mouse from "@/js/utils/mouse"
import { vec2, mat4 } from "gl-matrix"
import Node from '@/glxp/abstract/node'
import DebugController from '@/glxp/debug/debugController'
import Easing from "@/js/utils/easing"


const _VEC2 = vec2.create()

export default {
    name: 'home-phone-container',
    data: () => {
        return {
            hidden: true,
            ready: false,
            active: false,
            blur: false,
            background: false,
            intro: true,
            loaded: false,
            x: 0,
            y: 0,
            currentVideo: 0,
            dampedX: 0,
            dampedY: 0,
            s: 0.5,
            mobile: false,
            content: [],
        }
    },
    mounted() {

        this.$root.phone = this

        this.bounds = null
        this.width              = window.innerWidth
        this.height             = window.innerHeight
        this.scrollValue        = 0
        this.alpha              = 1
        this.ready              = false
        
        this.$nextTick(() => {
            if (!this.loaded) {
                this.glxp = WebglManager.getScene('main')
                this.glxp.on("loaded", () => {
                    this.init()
                    this.updatePos()
                    this.ready = true
                    this.loaded = true
                })
            }
        })
        RAF.suscribe('home-phone-update', this.update.bind(this))
        this.scrollListener = window.addEventListener("scroll", (evt)=>{
            this.scrollValue = window.pageYOffset
        })

        this.config = {
            damping: { value: 0.1, range: [0, 1] },
            strength_x: { value: 0.2, range: [0, 2] },
            strength_y: { value: 0.12, range: [0, 2] },
        }

        let panel = DebugController.addConfig(this.config, 'Phone')
        let tmp = { activate3DEffect: () => { this.active = !this.active } }
        panel.add(tmp, 'activate3DEffect')

        window.addEventListener("resize", () => { this.updatePos() })

        TweenMax.set(this.$refs["home-phone-canvas"], {
            autoAlpha: 0,
            y: 800,
        })
    },

    beforeMount() {
        let pageContent = this.$store.state.page.home.data.attributes
        this.content.push({
            video: true,
            loop: false,
            url: pageContent.introduction_phone.data.attributes.url ? this.getAssetUrl(pageContent.introduction_phone.data.attributes.url) : "/videos/video-placeholder.mp4"
        })  
        this.content.push({
            video: true,
            loop: true,
            url: pageContent.concept1.video ? this.getAssetUrl(pageContent.concept1.video.data.attributes.url) : "/videos/video-placeholder.mp4"
        })  
        this.content.push({
            video: true,
            loop: true,
            url: pageContent.concept2.video ? this.getAssetUrl(pageContent.concept2.video.data.attributes.url) : "/videos/video-placeholder.mp4"
        })  
        this.content.push({
            video: true,
            loop: true,
            url: pageContent.bank_loan.video ? this.getAssetUrl(pageContent.bank_loan.video.data.attributes.url) : "/videos/video-placeholder.mp4"
        }) 
    },

    created(){
        this.mobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },

    beforeDestroy() {
        RAF.unsuscribe('home-phone-update')
        RAF.unsuscribe('home-phone-scroll-update')
        window.removeEventListener("scroll", this.scrollListener)
    },

    methods: {

        init(){

            if (this.$refs["home-phone-canvas"] == undefined) {
                return
            }
            
            this.bgImg = new Image()
            this.bgImg.src = this.glxp.textureLoader.getImage('phoneBlackColorTransparent').src
            
            this.maskImage = new Image()
            this.maskImage.src = this.glxp.textureLoader.getImage('phoneMask').src

            this.ctx = this.$refs["home-phone-canvas"].getContext("2d")

            this.itemsImages = []
            for (let i = 0; i < this.$refs.items.length; i++) {
                this.itemsImages.push(
                    {
                        video: this.$refs.items[i].querySelector("video"),
                        offset: i,
                        canPlay: false,
                        active: false
                    }
                )
                this.$refs.items[i].querySelector("video").addEventListener("canplaythrough", (event)=>{
                    this.itemsImages[i].canPlay = true
                    if (this.itemsImages[i].active == true) {
                        this.itemsImages[i].video.play()
                    } else {
                        this.itemsImages[i].video.pause()
                    }
                })
            }
            TweenMax.delayedCall(1.5, ()=>{
                TweenMax.fromTo(this.$refs["home-phone-canvas"], 
                {
                    autoAlpha: 0,
                    y: 0,
                    duration: 0.5,
                    delay: 1,
                    ease: "expo.out",
                },
                {
                    autoAlpha: 1,
                    y: 420,
                    duration: 3,
                    ease: "expo.out",
                })
            })

        },

        update(){

            this.$el.style.opacity = this.alpha

            if (this.ready == false) {
                return
            }

            if (this.alpha == 0) {
                return
            }

            let scrollIntro = Math.min(this.scrollValue / this.height, 1)
            let scrollRegister = Math.min(Math.max((this.scrollValue / this.height) - 1, 0), 2)

            let introOffset = Easing.easeInOutQuad(1 - scrollIntro) * 0.6
            let introScale = Easing.easeInQuad(1 - scrollIntro) * .2

            let secondSectionOffset = Easing.easeInOutQuad(smoothstep(0.3, 1.75, scrollRegister))
            let firstSectionOffset = Easing.easeInOutQuad(smoothstep(0.4, 1, scrollIntro))

            if (scrollIntro < 2) {
                this.x = .33 - (secondSectionOffset * .86)
            } else {

            }
            if(scrollIntro > 0.1){
                TweenMax.to(this.$refs["home-phone-canvas"], {
                    y: 0,
                    duration: 2.6,
                    ease: "expo.out",
                })
            }else{
                TweenMax.to(this.$refs["home-phone-canvas"], {
                    y: 420,
                    duration: 1,
                    ease: "expo.out",
                })
            }

            
            let tmpX = this.x - this.dampedX
            // tmpX *= 0.75
            this.dampedX += tmpX

            let tmpY = this.y - this.dampedY
            // tmpY *= 0.75
            this.dampedY += tmpY

            let s = this.s + introScale

            this.drawContent()

            this.$el.style.transform = 
            `translate(${ -this.bounds.width / 2 + this.dampedX * this.width * .5}px, 
                       ${ -this.bounds.height / 2 + (this.dampedY) * this.height * .5 }px)`
                },

        roundedRectangle(ctx, x, y, w, h, r){
            if (w < 2 * r) r = w / 2;
            if (h < 2 * r) r = h / 2;
            ctx.beginPath();
            ctx.moveTo(x+r, y);
            ctx.arcTo(x+w, y,   x+w, y+h, r);
            ctx.arcTo(x+w, y+h, x,   y+h, r);
            ctx.arcTo(x,   y+h, x,   y,   r);
            ctx.arcTo(x,   y,   x+w, y,   r);
            ctx.closePath();
        },

        drawContent(){
            const ctx = this.ctx
            const w = this.bounds.width * this.dpr
            const h = this.bounds.height * this.dpr
            ctx.clearRect(0, 0, w, h)

            // Mask
            // ctx.drawImage(this.maskImage, 0, 0, w, h)
            // ctx.globalCompositeOperation='source-in'
            // ctx.fillStyle = "#1d2233"
            // ctx.fillRect(0, 0, w, h)
            // ctx.globalCompositeOperation='source-over'
            
            let marginW = 0.05
            let marginH = 0.02
            
            ctx.save()
            this.roundedRectangle(ctx, w*0.05, h*0.026, w * 0.9, h * 0.95, w*.1)
            ctx.clip()

            ctx.fillStyle = "#1d2233"
            ctx.fillRect(0, 0, w, h)
            
            for (let i = 0; i < this.itemsImages.length; i++) {
                let offset = this.itemsImages[i].offset * w
                if (this.itemsImages[i].active) {
                    ctx.drawImage(this.itemsImages[i].video, offset + (w * marginW), (h * marginH), w - (w * marginW * 2), h - (h * marginH * 2))
                }
            }
            ctx.restore()

            // Draw frame 
            ctx.drawImage(this.bgImg, 0, 0, w, h)
            ctx.globalCompositeOperation='source-over'
            
        },

        updatePos() {
             if (this.$refs["home-phone-canvas"] == undefined) {
                return
            }
            this.bounds = this.$el.getBoundingClientRect()
            this.dpr = Math.min(window.devicePixelRatio, 2)
            this.$refs["home-phone-canvas"].width = this.bounds.width * this.dpr
            this.$refs["home-phone-canvas"].height = this.bounds.height * this.dpr
            this.$refs["home-phone-canvas"].style.width = this.bounds.width + "px"
            this.$refs["home-phone-canvas"].style.height = this.bounds.height + "px"
            this.width = window.innerWidth
            this.height = window.innerHeight
        },

        gotToSlide(id){

            if (this.itemsImages[id] == undefined) {
                return
            }

            let previousVideo = this.currentVideo
            this.currentVideo = id
            this.itemsImages[previousVideo].video.pause()

            
            // let itemTarget = -this.$refs["items"][id].offsetWidth * 1.04 * id
            for (let i = 0; i < this.content.length; i++) {
                const content = this.content[i];
                TweenMax.to(this.itemsImages[i], {
                    duration: 1,
                    offset: i - id,
                    ease: "power2.out",
                    onComplete: ()=>{
                        if (content.video) {
                            if (previousVideo !== this.currentVideo) {
                                this.itemsImages[previousVideo].active = false
                                this.itemsImages[previousVideo].video.pause()
                                this.itemsImages[previousVideo].video.currentTime = 0
                            }
                        }
                    }
                })
            }
            
            this.itemsImages[id].video.play()
            this.itemsImages[id].active = true
        },

        show(){
            this.hidden = false
            TweenMax.delayedCall(2.5, () => {
                if (this.itemsImages != undefined) {
                    this.gotToSlide(0)
                }
            })
            TweenMax.to(this, {
                s: 1,
                delay: 0.2,
                duration: 2,
                ease: "power2.out"
            })
        },

        getAssetUrl(path){
            return `${path}`
        },

    }
}