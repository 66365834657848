import SectionMixin from '@/mixins/sectionMixin'


export default {
    name: 'feature-cards-block',
    mixins: [SectionMixin],
    components: {
    },
    props: {
        imgUrl: String,
        videoUrl: String,
        number: String,
        title: String,
        text: String,
        cards: Array,
        alternate: Boolean,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}