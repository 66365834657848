import SectionMixin from '@/mixins/sectionMixin'
import TextWysiwyg from '@/components/global/text-wysiwyg'
import DownloadAppButton from '@/components/global/download-app-button'


export default {
    name: 'introduction',
    mixins: [SectionMixin],
    props: ["title", "description", "cover","imgUrl","videoUrl"],
    components: {
        'text-wysiwyg': TextWysiwyg,
        'download-app-button': DownloadAppButton,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show(){
            this.visible = true
            this.$refs["download-app"].visible = true
        },
    }
}