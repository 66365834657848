import SectionMixin from '@/mixins/sectionMixin'

export default {
    name: 'feature-splited-text-block',
    mixins: [SectionMixin],
    components: {
    },
    props: {
        number: String,
        title: String,
        subTitle: String,
        description: String,
    },
    data: () => {
        return {
            isIntersecting: false,
            visible: false,
        }
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
        show() {
            if (this.visible) {
                return
            }
            this.visible = true
        },
    }
}